import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux"

function SideBar(){
    const state=useSelector(state=>state.userReducer.userDetails)

    return(
        <>
        <div className="sidebar mobile-side">
                <div className="container-fluid">

                <div className="profile-info">
                    <div className="img-section">
                        <h2>{state.email ? state.email.slice(0,2).toUpperCase() : "NA"}</h2>
                    </div>
                    <div className="p-footer mt-2 mb-3">
                        <span>{state ? (state.email || `+${state.phone}`) : "wassi@gmail.com"}</span>
                    </div>
                </div>
                <ul>
                    <li><Link to={'/dashboard'}><i class="fa-regular fa-circle-user"></i><span>Dashboard</span></Link></li>
                    <li><Link to={'/security'}><i class="fa-solid fa-shield"></i><span>Security</span></Link></li>
                    <li><Link to={'/kyc'}><i class="fa-solid fa-pen-to-square"></i><span>Personal Info</span></Link></li>
                    <li><Link to={'/payment'}><i class="fa-brands fa-bitcoin"></i><span>Payment</span></Link></li>
                    <li><Link to={'/setting'}><i class="fa-solid fa-gear"></i><span>Settings</span></Link></li>

                </ul>
                </div>

            </div>
        </>
    )
}
export default SideBar;