import { removeLoginId } from "../../store/actions";

const { default: axios } = require("axios");

const fetchLoginLogs = async () =>{
    const data=await axios.get(`${process.env.REACT_APP_API_URL}/api/login-logs`,{
        headers:{
            Authorization:`Bearer ${localStorage.getItem("token")}`
        }
    })
    // console.log(data.data)
    return data.data.login_logs
}


const fetchLoginDevice = async (id) =>{
    const data=await axios.get(`${process.env.REACT_APP_API_URL}/api/login-log/${id}`,{
        headers:{
            Authorization:`Bearer ${localStorage.getItem("token")}`
        }
    })
    // console.log(data.data)
    return data.data.login_log
}

const deleteLog = async (id,dispatch) =>{
    const data=await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-login-log/${id}`,{
        headers:{
            Authorization:`Bearer ${localStorage.getItem("token")}`
        }
    })
    dispatch(removeLoginId())
    // console.log(data.data)
    // return data.data.login_logs
}



// router.delete("/delete-login-log",async (req,res)=>{

export { 
    fetchLoginLogs ,
    deleteLog,
    fetchLoginDevice
}