import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteLog, fetchLoginLogs } from "../../Services/Auth/loginlogService";


function Accountlogon(props){
    const [loginLogs, setloginLogs] = useState([])

    const dispatch=useDispatch()

    useEffect(() => {
        const call = async () => setloginLogs(await fetchLoginLogs())
        call()
    }, [])


    const deletelog = async (id) =>{
        await deleteLog(id,dispatch)
        setloginLogs(await fetchLoginLogs())
    }

    const setTime = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString() + " " + d.toLocaleTimeString();
        return date
    }
    return(

        <>
        {props.header}
        <div className="dashboard-section">
            {props.sidebar}
            <div className="dashboard-main">

                    <div className="back-link">
                        <Link to={'/security'}><i class="fa-solid fa-angle-left"></i> Security</Link>
                    </div>

                    <h3 className="mb-4">Device Logon</h3>
                    <Table responsive="lg">

                            <thead className="t-heading grey-bg">

                                <tr>

                                <th>Device</th>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Ip Address</th>
                                {/* <th>Action</th> */}
                                
                                </tr>

                            </thead>

                            <tbody className="text-center align-items-center">

                                {
                                    loginLogs.length > 0 && loginLogs.map(log=>{
                                        return <tr key={log._id}>
                                        <td>{log.device} ({log.platform})</td>
                                        <td>{setTime(log.createdAt)}</td>
                                        <td>{log.location}</td>
                                        <td>{log.ip_address}</td>
                                        {/* <td>
                                            <Link to={'#'} className="red" onClick={() => deletelog(log._id)}>Delete</Link> 
                                        </td> */}
                                    </tr>
                                    }) 
                                }

                            </tbody>


                    </Table>

                    
                
            </div>
        </div>
        </>

    )


}

export default Accountlogon;