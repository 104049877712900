import { useState } from "react";
import { Modal, Form } from "react-bootstrap"
import axios from "axios"



function KYCModal(props){
    const [step,setStep] = useState(1);
    const [preview1,setPreview1] = useState('');
    const [preview2,setPreview2] = useState('');
    const [preview3,setPreview3] = useState('');

    const [image,setImage] = useState("")

    // error hooks
    const [firstNameError,setFirstNameError] = useState(false)
    const [lastNameError,setLastNameError] = useState(false)
    const [dobError,setDobError] = useState(false)
    const [residentAddressError,setResidentAddressError] = useState(false)
    const [cityError,setCityError] = useState(false)
    const [postalCodeError,setPostalCodeError] = useState(false)


    // Mutahir Commit

    const [error, setError] = useState()
    const [step1Values, setStep1Values] = useState({
        firstName: "",
        lastName: "",
        middleName: "",
        birth: "",
        city: "",
        postalCode: "",
        img: "",
      });

      const [step2Values, setStep2Values] = useState({
        address: "",
        city: "",
        postalCode: "",
    })

      const step1handleChange = (prop) => (event) => {
        if(prop==="firstName")
            event.target.value == "" ? setFirstNameError(true) : setFirstNameError(false)
        if(prop==="lastName")
           event.target.value == "" ? setLastNameError(true) : setLastNameError(false)
        if(prop==="birth")
            event.target.value == "" ? setDobError(true) : setDobError(false)
        setStep1Values({ ...step1Values, [prop]: event.target.value });
      };

      const validation = (step) =>{
          if(step==1){
              step1Values.firstName == "" ? setFirstNameError(true) : setFirstNameError(false)
              step1Values.lastName == "" ? setLastNameError(true) : setLastNameError(false)
              step1Values.birth == "" ? setDobError(true) : setDobError(false)
              console.log(firstNameError,lastNameError,dobError," ERRORS!!")
              if(!firstNameError && !lastNameError && !dobError && step1Values.firstName != "" && step1Values.lastName != "" && step1Values.birth != "")
                   setStep(2)
         }
         else if(step==2){
            step2Values.address == "" ? setResidentAddressError(true) : setResidentAddressError(false)
            step2Values.postalCode == "" ? setPostalCodeError(true) : setPostalCodeError(false)
            step2Values.city == "" ? setCityError(true) : setCityError(false)
            if(!residentAddressError && !postalCodeError && !cityError &&  step2Values.address != "" && step2Values.city != "" && step2Values.postalCode != "")
                 setStep(3)
         }
      }

 

      const step2handleChange = (prop) => (event) => {
        if(prop==="address")
            event.target.value == "" ? setResidentAddressError(true) : setResidentAddressError(false)
        if(prop==="city")
            event.target.value == "" ? setCityError(true) : setCityError(false)
        if(prop==="postalCode")
            event.target.value == "" ? setPostalCodeError(true) : setPostalCodeError(false)
        setStep2Values({ ...step2Values, [prop]: event.target.value });
      };

       
      
    //   console.log("step1Values", step1Values)
    //   console.log("step2Values", step2Values)

      const Kyc1Auth = async () => {
        try{
            if(image){
                const formData = new FormData()
                formData.append("image", image)
                formData.append("firstName", step1Values.firstName)
                formData.append("middleName", step1Values.middleName)
                formData.append("lastName", step1Values.lastName)
                formData.append("dob", step1Values.birth)
                formData.append("residentAddress", step2Values.address)
                formData.append("city", step2Values.city)
                formData.append("postalCode", step2Values.postalCode)
    
                const kyc1 = await axios.post(`${process.env.REACT_APP_API_URL}/api/kyc`,formData ,
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )
                localStorage.setItem("kyc1btn_disable", true)
                props.kyc_close()    
            }else{
                setError("Image is not Selected! Try Again!")
            }

        }
        catch(error){
            setError(error.response.data.message)
            
        }
    }

    const handleChangeImage = (e) => {
        let ext=e.target.files[0].name.split(".").pop()
        console.log(ext)
        if(ext == "jpg" || ext ==="jpeg" || ext ==="png"){
            if(e.target.files[0]){
                setPreview1({[e.target.name]: URL.createObjectURL(e.target.files[0])})
                setImage(e.target.files[0])
            }
            setError("")
            
        }
        else{
            setError("Image Format not Supported! Try Above formats")
        }
     }
     const handleChangeImage2 = (e) => {
         console.log('ddsfasdfasfasd'.e);
        if(e.target.files[0]){
            setPreview2({[e.target.name]: URL.createObjectURL(e.target.files[0])})
        }

     }
     const handleChangeImage3 = (e) => {
        if(e.target.files[0]){
            setPreview3({[e.target.name]: URL.createObjectURL(e.target.files[0])})
        }

     }
    return (<>
         <Modal show={props.show} onHide={props.kyc_close} centered className="security-verify-modal">
                    
                

                    <Modal.Body className="box-bg">

                    <i class="fa-solid fa-xmark" onClick={props.kyc_close}></i>

                    <div className="modal-title text-center">
                        {
                            step == 1 &&
                            <h3>Personal Information</h3>
                        }
                        {
                            step == 2 &&
                            <h3>Residential Information</h3>
                        }
                         {
                            step == 3 &&
                            <h3>Identity Verification</h3>
                        }

                    </div>
                    {
                        step == 1 &&
                        <div className="first">
                    <Form.Group className="mt-3 " controlId="first">
                        <Form.Label>First Name</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="text" value={step1Values.firstName} onChange={step1handleChange("firstName")}/>
                        </div>
                    </Form.Group>
                    {firstNameError && (<p className="validation_error"> First Name cannot be Empty </p>)}
                        <Form.Group className="mt-3 " controlId="last">
                        <Form.Label>Last Name</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="text" value={step1Values.lastName} onChange={step1handleChange("lastName")} />
                        </div>
                    </Form.Group>
                    {lastNameError && (<p className="validation_error"> Last Name cannot be Empty </p>)}
                    <Form.Group className="mt-3 " controlId="confirm">
                        <Form.Label>Middle Name (Optional)</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="text" value={step1Values.middleName} onChange={step1handleChange("middleName")} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-3 " controlId="confirm">
                        <Form.Label>Date Of Birth</Form.Label>
                        <div className='position-relative'>
                        <Form.Control value={step1Values.birth} onChange={step1handleChange("birth")} type="date" />
                        </div>
                    </Form.Group>
                    {dobError && (<p className="validation_error"> Date of Birth cannot be Empty </p>)}
                    </div>
                    }
                    {
                        step == 2 &&
                        <div className="second">
                            <Form.Group className="mt-3 " controlId="addr">
                        <Form.Label>Resident Address</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="text" value={step2Values.address} onChange={step2handleChange("address")} />
                        </div>
                        </Form.Group>
                        {residentAddressError && (<p className="validation_error"> Residential address cannot be Empty </p>)}

                        <div className="d-flex justify-content-between">
                        <Form.Group className="mt-3 " controlId="city">
                            <Form.Label>City</Form.Label>
                            <div className='position-relative'>
                            <Form.Control type="text" value={step2Values.city} onChange={step2handleChange("city")} />
                            </div>
                        </Form.Group>
                        <Form.Group className="ml-1 mt-3 " controlId="postal">
                            <Form.Label>Postal Code</Form.Label>
                            <div className='position-relative'>
                            <Form.Control type="text" value={step2Values.postalCode} onChange={step2handleChange("postalCode")} />
                            </div>
                        </Form.Group>
                        </div>
                        <div className="d-flex justify-content-between">
                            {cityError && (<p className="validation_error"> City cannot be Empty </p>)}
                            {postalCodeError && (<p className="validation_error"> Postal Code cannot be Empty </p>)}
                        </div>

              
                        </div>
                    }
                    {
                        step == 3 &&
                        <>
                        <div className="mt-3">
                        <span className="f-bold ">Upload a photo of Passport</span>
                        </div>

                        <div className="upload-box">
                           
                            <input type="file" style={{display:'none'}} name="passport" accept="image/*" id="passport" onChange={(e)=>handleChangeImage(e)}/>
                            <label className="upload-icon" for="passport">
                            {
                                preview1 != "" ?
                                <img src={preview1['passport']} alt="upload image" width={150} className="upload-img pointer"/>
                                :<i class="fa-solid fa-camera"></i>
                            }
                            </label>
                        </div>
                        <div className="mt-3">
                        <span className="">File size must be between 10Kb and 5120KB in ..jpg/.jpeg/.png/ format.</span>
                        </div>
                        </>
                    }
                    
                    <div className="text-center">
                    {
                        step == 1 &&
                        <button className="mt-5 w-100 custom-icon-btn primary-btn" onClick={() => validation(1)}>Continue</button>
                    }
                    {
                        step == 2 &&
                        <div className="d-flex mt-5">
                        <button className="custom-icon-btn secondary-btn" onClick={(e)=>setStep(1)}>Back</button>
                        <button className=" custom-icon-btn primary-btn" onClick={() => validation(2)}>Continue</button>
                        </div>
                    }
                    {
                        step == 3 &&
                        <div>
                            <div style={{paddingTop:"1rem", color:"red"}}>{error}</div>
                            <div className="d-flex mt-5">
                        <button className="custom-icon-btn secondary-btn" onClick={(e)=>setStep(2)}>Back</button>
                        
                        <button  className=" custom-icon-btn primary-btn" onClick={Kyc1Auth}>Finish</button>
                        </div>
                        </div>
                        
                    }
                    </div>

                  
                    

                    </Modal.Body>
        
            </Modal>
    </>)
}

export default KYCModal;