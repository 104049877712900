import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Row,Col,Table } from "react-bootstrap";
import { Link } from "react-router-dom";


function TradeOrder(props){
    const [userOrdersHistory,setUserOrdersHistory]=useState([])   

    const [fromDate,setFromDate] = useState("")
    const [toDate,setToDate]=useState("")
    const [type, setType] = useState("All")


    const get_orders_history = async () => {
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_orders_history`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setUserOrdersHistory([orders.data.sell_orders,orders.data.buy_orders])
    }

    useEffect(() => {
        get_orders_history()
    }, [])


    const setDate = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString() + " " + d.toLocaleTimeString();
        return date
    }


    const fetchCustomDateOrders = async () =>{
        if(fromDate!="" && toDate!=""){
            try{
                const orders=await axios.post(`${process.env.REACT_APP_API_URL}/api/get_custom_user_orders`,{
                    from:fromDate,
                    to:toDate
                },
                {
                    headers:{
                    "Authorization": "Bearer "+ localStorage.getItem("token")
                }})
                console.log("Type",type)
                if(type=="All")
                    setUserOrdersHistory([orders.data.sell_orders,orders.data.buy_orders])
                else if(type=="Buy")
                    setUserOrdersHistory([[],orders.data.buy_orders])
                else
                    setUserOrdersHistory([orders.data.sell_orders,[]])
            }
            catch(e) {
                console.log(e)
            }
        }
        else{
            alert("Please select From date and To date!")
        }
    }

    return(
        <>
            {props.header}

            <div className="dashboard-section order-body-width">
                {props.sidebar}
                <div className="dashboard-main ">
                <div className="normal-box big-bg order-head-mobile">
                   <div>
                   <span>Spot</span>
                    <h3>Order History</h3>
                   </div>
                    <i class="fa-solid fa-angle-right open-order-sidebar"></i>
                </div>
                <div className="dashboard-margin">
                <div className="history-filter">
                <Form.Group className="mt-3 div" controlId="from">
                  <Form.Label>From</Form.Label>
                  <Form.Control type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)}  />
                </Form.Group>
                <Form.Group className="mt-3 div" controlId="to">
                  <Form.Label>To</Form.Label>
                  <Form.Control type="date"  value={toDate} onChange={(e) => setToDate(e.target.value)} />
                </Form.Group>
                <Form.Group className="mt-3 div" controlId="Pair">
                <Form.Label>Pair</Form.Label>
                <Form.Select >
                <option>All</option>
                <option value="1">1INCH</option>
                <option value="2">1INCHDOWN</option>
                <option value="3">1INCHUP</option>
                 </Form.Select>
                 </Form.Group>
                 <Form.Group className="mt-3 div" controlId="Coin">
                <Form.Label>Coin</Form.Label>
                <Form.Select >
                <option>All</option>
                <option value="1">USDT</option>
                <option value="2">BUSD</option>
                <option value="3">BNB</option>
                 </Form.Select>
                 </Form.Group>
     
                 <Form.Group className="mt-3 div" controlId="side">
                <Form.Label>Side</Form.Label>
                <Form.Select value={type} onChange={(e) =>{setType(e.target.value)}}>
                <option value="All">All</option>
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
                 </Form.Select>
                 </Form.Group >
                 <div className="mt-5 div margin-between-btn">
                     <button className="custom-btn primary-btn" onClick={fetchCustomDateOrders}>Search</button>
                     <button className="custom-btn secondary-btn " onClick={() => {
                                    setFromDate("")
                                    setToDate("")
                                    setType("All")}}> Reset</button>
                 </div>
                </div>
                </div>
                <div className="mt-3 table-responsive">
                <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Side</th>
                            <th>Average</th>
                            <th>Price</th>
                            <th>Executed</th>
                            <th>Amount</th>
                            <th>Total</th>
                            <th>Trigger Conditions</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                                userOrdersHistory.length !=0 && (userOrdersHistory[0].length !=0 && (
                                    userOrdersHistory[0].map(order=>{
                                        return <tr key={order._id}>
                                            <td>{setDate(order.createdAt)}</td>
                                            <td>LUNA/USDT</td>
                                            <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit") || (order.is_market && "Market")}</td>
                                            <td><span className="text-lightred">Sell</span></td>
                                            <td>--------</td>
                                            <td>{(order.is_stop_limit && order.limit) || (order.price)}</td>
                                            <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)*10000)/10000}%</td>
                                            <td>{order.order_amount}</td>
                                            <td>{order.total}</td>
                                            <td>{(order.is_stop_limit && "<="+ order.stop) || ("----------")}</td>
                                            <td>{(order.amount==0 && "Completed") || (order.amount==order.order_amount && "Not Executed") || (order.amount!=0 && "Partial Executed")}</td>
                                        </tr>
                                    })
                                ))
                            }   
                            {
                                 userOrdersHistory.length !=0 && (userOrdersHistory[1].length !=0 && (
                                    userOrdersHistory[1].map(order=>{
                                        return <tr key={order._id}>
                                            <td>{setDate(order.createdAt)}</td>
                                            <td>LUNA/USDT</td>
                                            <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit") || (order.is_market && "Market")}</td>
                                            <td><span className="text-lightgreen">Buy</span></td>
                                            <td>--------</td>
                                            <td>{(order.is_stop_limit && order.limit) || (order.price)}</td>
                                            <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)*10000)/10000}%</td>
                                            <td>{order.order_amount}</td>
                                            <td>{order.total}</td>
                                            <td>{(order.is_stop_limit && ">="+ order.stop) || ("----------")}</td>
                                            <td>{(order.amount==0 && "Completed") || (order.amount==order.order_amount && "Not Executed") || (order.amount!=0 && "Partial Executed")}</td>
                                        </tr>
                                    })
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
                </div>
             
            
            </div>

          

        </>

    )

}

export default TradeOrder