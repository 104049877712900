// user data actions.
export const fetchUserData = (user) => {return {type:"FETCH_USER_DATA", payload:user}}
export const clearUserData = () => {return {type:"CLEAR_USER_DATA", payload:{}}}

// device id actions.
export const addLoginId = (id) => {return {type:"ADD_LOG_ID", payload:id}}
export const removeLoginId = () => {return {type:"REMOVE_LOG_ID", payload:""}}

// user Setting actions.
export const changeSettings = (data) => {return {type:"CHANGE_SETTINGS",payload:data}}
export const initiateSettings = (data) => {return {type:"INITIATE_SETTINGS",payload:data}}
export const removeSettings = () => {return {type:"REMOVE_SETTINGS", payload:{}}}

