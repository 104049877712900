import axios from "axios";
import { useState } from "react"
import { Container, Form, Row } from "react-bootstrap"
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from "react-router-dom";

function Verification(props){
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate()
    const {state}=useLocation()
    const [error,setError] = useState("")

    const Verify = async (e)=>{
        e.preventDefault();
        
        try {
            const id = state.id

            let data={
                userId:id,
                otp:OTP
            }
            const verifyotp = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/verify-otp`, data)
            if(verifyotp.data.status=="VERIFIED")
            {
                navigate('/welcome',{state:{id:id}})            
            }
            else{
                setError(verifyotp.data.message)
            }
            // localStorage.setItem("token", 'testing');
        } catch (error) {
            console.log(error)
        }
    }


    const resendCode = async () =>{
        try {
            const id = state.id
            let data={
                userId:id
            }
            const resent = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/resend-otp`,data)            
        } 
        catch (error) {
            console.log(error)
        }

    }
    return (
        <>
        {props.header}
        <Container>
        <div className="section-margin">
        <Row>
               <div className="center-form col-lg-6 col-12">
               <main className="form-section">
               <div className="form">
               <Form onSubmit={(e) => {
				Verify(e);
				}}>
               <h1 className="h3 mb-3 fw-normal text-center">Phone Verification</h1>
               <div className="text-center mb-2">
               <span className="text-center">Please enter the 6 digit verification  code <br/> that was sent to you.<br/>The code is valid</span>
               </div>
               <div className="text-center digit-group">
               <OtpInput
               value={OTP}
               onChange={setOTP}
               numInputs={6}
               isInputNum={true}
                />
                </div>
                {
                    error ? <div className="d-flex error_otp"> <p className="text-lightred mt-4">{error} </p> <button type="button" onClick={resendCode}>Resend Code</button> </div> : " "
                }
                <button className={`w-100 custom-btn primary-btn ${error ? "mt-1" : "mt-5"}`} type="submit">Proceed</button>
                </Form>
                </div>
               </main>
               </div>
        </Row>
        </div>
        </Container>
        </>
    )
}
export default Verification