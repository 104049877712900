const { default: axios } = require("axios");

const updateSettings = async (key,value) =>{
    const data={
        key,
        value
    }
    const headers={
        headers:{
            Authorization:`Bearer ${localStorage.getItem("token")}`
        }
    }
    try {
        const update=await axios.post(`${process.env.REACT_APP_API_URL}/api/user-settings`,data,headers)  
        // console.log(update.data.message)
        // return update.data.data      
    } catch (error) {
        console.log(error.response)
    }
}

const getSettings = async () =>{
    try {
        const headers={
            headers:{
                Authorization:`Bearer ${localStorage.getItem("token")}`
            }
        }
        const data=await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings`,headers)  
        return data.data.settings
    } catch (error) {
        console.log(error.response)
    }
}

export {
    updateSettings,
    getSettings
}