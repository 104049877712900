import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap"
import axios from "axios";


function KYCLevel2Modal(props) {
    const [preview2, setPreview2] = useState('');
    const [preview3, setPreview3] = useState('');

    const [front, setFront] = useState('')
    const [back, setBack] = useState('')

    const[error, setError] = useState()
    const[disabled, setDisabled] = useState(false)


    const handleChangeImage2 = (e) => {
        // console.log('ddsfasdfasfasd'.e);
        let ext=e.target.files[0].name.split(".").pop()
        if(ext == "jpg" || ext =="jpeg" || ext =="png"){
            if (e.target.files[0]) {       
                setPreview2({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
                console.log()
                setFront(e.target.files[0])
            }  
            if(disabled){
                setError("level 1 kyc is not created yet")
            }
            else{
                setError("")
            }
        }
        else{
            setError("Image Format not Supported! Try Above formats")
        }
    }
    const handleChangeImage3 = (e) => {
        let ext=e.target.files[0].name.split(".").pop()
        console.log(ext)
        if(ext == "jpg" || ext ==="jpeg" || ext ==="png"){
            if (e.target.files[0]) {
                setPreview3({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
                setBack(e.target.files[0])
            }
            if(disabled){
                setError("level 1 kyc is not created yet")
            }
            else{
                setError("")
            }
        }else{
            setError("Image Format not Supported! Try Above formats")
        }
    }


    useEffect(() => {
        if(!props.check){
            setError("level 1 kyc is not created yet")
            setDisabled(true)
        }
        else{
            setError("")
            setDisabled(false)
        }
    }, [props.kyclevel2show])
    

    const IdenVerification = async (e) => {
        try {
            e.preventDefault()
            if(preview2 !== "" && preview3 !== ""){
                // console.log("hello")

                const formData = new FormData()
                formData.append("front", front)
                formData.append("back", back)

                // console.log("formData", formData)
               
                const identity = await axios.post(`${process.env.REACT_APP_API_URL}/api/kyc-level2`, formData,
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token"),
                        'content-type': 'multipart/form-data'
                    }
                })
                localStorage.setItem("kyc2btn_disable", true)
                setDisabled(true)
                props.kyclevel2_close()
                // console.log("identity", identity)
                
            }
            else{
                setError("Select the images then finish")
            }
            
        }
        catch (error) {
            setError(error.response.data.message)
            setDisabled(true)
            console.log(error.response.data.message)
        }
    }



    return (<>
        <Modal show={props.kyclevel2show} onHide={props.kyclevel2_close} centered className="security-verify-modal">



            <Modal.Body className="box-bg">

                {/* <i class="fa-solid fa-xmark" onClick={props.kyc_close}></i> */}

                <form onSubmit={IdenVerification}>
                <div className="modal-title text-center">
                    <h3>Identity Verification</h3>

                </div>
                <div className="mt-3">
                    <span className="f-bold ">Government issued ID Card/Passport/Driver's License</span>
                </div>

                <div className="image-upload-flex">
                    <div className="upload-box">

                        <input type="file" style={{ display: 'none' }} name="front" accept="image/*" id="front" onChange={(e) => handleChangeImage2(e)} />
                        <label className="upload-icon" for="front">
                            {
                                preview2 != "" ?
                                    <img src={preview2['front']} alt="upload image" width={170} height={120} className="upload-img pointer" />
                                    :
                                    <div>
                                        <i class="fa-solid fa-camera"></i>
                                        <p className="m-0">Front</p>
                                    </div>
                            }
                        </label>
                    </div>
                    <div className="upload-box">

                        <input type="file" style={{ display: 'none' }} name="back" accept="image/*" id="back" onChange={(e) => handleChangeImage3(e)} />
                        <label className="upload-icon" for="back">
                            {
                                preview3 != "" ?
                                    <img src={preview3['back']} alt="upload image" width={170} height={120}  className="upload-img pointer" />
                                    :
                                    <div>
                                        <i class="fa-solid fa-camera"></i>
                                        <p className="m-0">Back</p>
                                    </div>
                            }
                        </label>
                    </div>
                </div>
                <div className="mt-3">
                    <span className="">File size must be between 10Kb and 5120KB in ..jpg/.jpeg/.png/ format.</span>
                </div>

                <div className="text-center">
                <div style={{paddingTop:"1rem", color:"red"}}>{error}</div>
                    <div className="d-flex mt-5">
                        <button disabled={disabled} className="w-100 custom-icon-btn primary-btn" type="submit">Finish</button>
                    </div>
                </div>
                </form>




            </Modal.Body>

        </Modal>
    </>)
}

export default KYCLevel2Modal;