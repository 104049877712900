import { useState } from "react";
import { Row,Col, Tab, Tabs, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Toggleswitch  from "../../components/misc-components/toggle";
import { getSettings, updateSettings } from "../../Services/SettingsService";
import { changeSettings } from "../../store/actions";


function Setting(props){
    // State From redux, 
   const state=useSelector(state=>state.settingReducer.settings) ?? {}

   const [limitNotification, setLimitNotification] = useState(state.limit_notification ?? false)
   const [stopLimitNotification, setStopLimitNotification] = useState(state.stop_limit_notification ?? false)
   const [activities, setActivities] = useState(state.activities ?? false)
   const [tradeNotification, setTradeNotification] = useState(state.trade_notification ?? false)
   const [payrexaNews, setPayrexaNews] = useState(state.payrexa_news ?? false)
   const [systemMessages, setSystemMessages] = useState(state.system_messages ?? false)

    const dispatch=useDispatch()

    const Update = async (key,value) =>{
        await updateSettings(key,value)
        dispatch(changeSettings({key,value}))
    }

    return (
        <>
        {props.header}
        <div className="dashboard-section">

            {props.sidebar}

            <div className="dashboard-main">

                <div className="normal-box big-bg">

                    <h3 className="mt-3 mb-3">Settings</h3>

                </div>

                <div className="dashboard-margin">
                <Row>
                    <Col lg={12}>
                    <div class="normal-box">
                        <h4>Order Confirmation</h4>
                        <p className="f-bold">If the order reminder function is enabled, it will need to be reconfirmed every time an order is submitted</p>
                        <hr/>
                        <div className="security-section setting-section">
                            <ul>
                                <li>
                                       
                                    <span>Limit Order</span>

                                       <Toggleswitch onClick={()=> Update("limit_notification",!limitNotification)} setState={setLimitNotification} state={limitNotification} />


                                </li>

                                

                                <li>

                                       
                                    <span>Stop-Limit Order</span>
                                    <Toggleswitch onClick={()=> Update("stop_limit_notification",!stopLimitNotification)} setState={setStopLimitNotification} state={stopLimitNotification} />

                                       
                                </li>

                                <div className="my-5">

                                <h4 className="">Notifications</h4>
                                <p className="f-bold">Once enabled, you will receive relevant notifications within the app and website.</p>

                                </div>

                                <hr/>

                                <li>   
                                        
                                    <span>Activities</span>
                                    <Toggleswitch onClick={()=> Update("activities",!activities)} setState={setActivities} state={activities}/>

                                </li>

                               

                                <li>
                                       
                                    <span>Trade Notification</span>
                                    <Toggleswitch onClick={()=> Update("trade_notification",!tradeNotification)} setState={setTradeNotification} state={tradeNotification}/>
                                        
                                </li> 

                                <li>
                                       
                                    <span>PayRexa News</span>
                                    <Toggleswitch onClick={()=> Update("payrexa_news",!payrexaNews)} setState={setPayrexaNews} state={payrexaNews}/>
                                           
                                </li> 

                                <li>
                                       
                                    <span>System Messages</span>
                                    <Toggleswitch onClick={()=> Update("system_messages",!systemMessages)} setState={setSystemMessages} state={systemMessages}/>
                                           
                                </li> 

                                

                               

                              

                            </ul>
                        </div>
                    </div>
                    </Col>
                </Row>
               
                </div>

            </div>
        </div>
        </>
    )
}
export default Setting