const initialState={}


const userReducer = (state=initialState,action) =>{
    if(action.type=="FETCH_USER_DATA"){
       return {...initialState , userDetails:action.payload}
    }
    if(action.type=="CLEAR_USER_DATA"){
       return {...initialState , userDetails:action.payload}
    }
    else
        return state
}

const deviceLogReducer = (state=initialState,action) =>{
   if(action.type=="ADD_LOG_ID"){
      return {...initialState ,logon_device:action.payload}
   }
   if(action.type=="REMOVE_LOG_ID"){
      return {...initialState ,logon_device:action.payload}
   }
   else
       return state
}


const settingReducer = (state=initialState,action) =>{
   if(action.type=="CHANGE_SETTINGS"){
      return {...initialState ,settings: {...state.settings, [action.payload.key]:action.payload.value }}
   }
   if(action.type=="INITIATE_SETTINGS"){
      return {...initialState ,settings: action.payload }
   }
   if(action.type=="REMOVE_SETTINGS"){
      return {...initialState ,settings:action.payload}
   }
   else
       return state
}

export {
   userReducer,
   deviceLogReducer,
   settingReducer
}
   
