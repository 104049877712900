import { useEffect, useState } from "react";
import { Container,Row,Col } from "react-bootstrap";
import { MDBDataTableV5 } from 'mdbreact';
import BTCImage from '../../../assets/images/btc.svg'
import ETHImage from '../../../assets/images/ETH.svg'
import USDTImage from '../../../assets/images/USDT.svg'
import { Link } from "react-router-dom";
import DepositIcon from '../../../assets/images/deposit-drop-icon.png';
import WithdrawIcon from '../../../assets/images/withdraw-icon.png';
import TransferIcon from '../../../assets/images/transefer-icon.png';
import TransferModal from "../../../components/modals/Transfer";
import axios from "axios";

function Spot(props){
    const [transfershow, setTransfershow] = useState(false);

    const transfer_close = () => setTransfershow(false);
    const transfer_show = () => setTransfershow(true);
    // for getting data
    const [userWallets,setUserWallets] = useState([])
    // for rendering in table with divs and spans
    const [Wallets,setWallets] = useState([])
    const [temp,setTemp] = useState([])
    const [hide,setHide] = useState(false)

    const [price1,setPrice1] = useState(0)
    const [price2,setPrice2] = useState(0)

    const [datatable, setDataTable] = useState()

    const [images,setImages] = useState([BTCImage,USDTImage,ETHImage])

    const [search,setSearch] = useState("")
  
    const fetchWallets= async () =>{
        const wallets=await axios.get(`${process.env.REACT_APP_API_URL}/api/user_wallets`,
        { 
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
        )
        if(wallets.data.wallets.length>0){

          wallets.data.wallets.map((wallet,i)=>{
            wallet["image"]=images[i]
          })
          setPrice1(wallets.data.wallets[0].balance + (wallets.data.wallets[1].balance/27605.93) + ((wallets.data.wallets[2].balance *1966.75) /27605.93))
          setPrice2((wallets.data.wallets[0].balance * 27605.93) + (wallets.data.wallets[1].balance ) + ((wallets.data.wallets[2].balance *1966.75)))
          setUserWallets(wallets.data.wallets)
          setTemp(wallets.data.wallets)
        }
        
    }

    useEffect(() => {
      fetchWallets()
    }, [])
    
    useEffect(() => {
        setWallets(
          userWallets.map((wallet,i)=>{
            return { 
              assets: <><div className="td-image-flex"><img src={wallet.image}/><div className="span-div"><span>{wallet.coin_id.name.toUpperCase()}</span><span>{wallet.coin_id.full_name}</span></div></div></>,
              total_ammount: (wallet.balance).toFixed(4),
              available: wallet.available_balance.toFixed(4),
              in_order: <Link to={'#'} className="anchor">{wallet.in_order_balance.toFixed(4)}</Link>,
              value: '0.0000000',
              action: <div className="td-image-flex">
              <Link to={'/wallet/deposit'} className="anchor">Deposit</Link>
              <Link to={'/wallet/withdrawal'} className="anchor">Withdraw</Link>
              <li className="dropdown table-dropdown p-0 m-0">
                      <Link to={"#"} className="anchor"  id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                      Trade
                      </Link>
  
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li><Link to={'#'}> BTC/AUD</Link></li>
                          <li><Link to={'#'}>BTC/BIDR</Link></li>
                          <li><Link to={'#'}>BTC/BRL</Link></li>
  
                      </ul>
  
               </li>
              <Link to={'#'} onClick={transfer_show} className="anchor">Transfer</Link>
              </div>, 
            }
          }
          ))
        
  
  
    }, [userWallets])
    
    useEffect(() => {
      setDataTable({
        columns: [
          {
            label: 'Assets',
            field: 'assets',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'assets',
            },
          },
          {
            label: 'Total Ammount',
            field: 'total_ammount',
            width: 200,
          },
          {
            label: 'Available',
            field: 'available',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'In Order',
            field: 'in_order',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'BTC Value',
            field: 'value',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: Wallets.length > 0 ? Wallets : []
      });
    }

    , [Wallets])
    
    const filterWallets = ()=>
    {   
        setUserWallets(temp.filter(wallet=> (wallet.coin_id.name.toLowerCase().startsWith(search.toLowerCase()) || wallet.coin_id.full_name.toLowerCase().startsWith(search.toLowerCase()))))
    }

    useEffect(() => {
      filterWallets()
    }, [search])
    

    return (
        <>
        {props.header}
        <div className="dashboard-section">

            {props.sidebar}
            <div className="dashboard-main">
                <div className="text-btn-flex normal-box big-bg">
                    <h3 className="">Spot</h3>
                    <div className="four-btns">
                        <Link to={'/wallet/deposit'} className="custom-btn primary-btn">Deposit</Link>
                        <Link to={'/wallet/withdrawal'} className="custom-btn secondary-btn">Withdraw</Link>
                        <button className="custom-btn secondary-btn" onClick={transfer_show}>Transfer</button>
                        <Link to={'/wallet/deposit/history'} className="custom-btn secondary-btn">History</Link>
                    </div>
                </div>
                <div className="dashboard-margin">
                <div className="text-btn-flex normal-box">
                    <div>
                    <div className="mb-3 d-flex align-items-center estimate-blnc">
                    <h5 className="">Spot balance</h5>
                    <button className="ml-1 custom-btn secondary-btn" onClick={()=> !hide ? setHide(true) : setHide(false)} ><i className={`${!hide ? "fa fa-eye-slash" : "fa fa-eye"}`} style={{marginRight:'5px'}} ></i><span >{`${!hide ? "Hide Balance":"Show Balance"}`}</span></button>
                    </div>
                    <h5 > {`${!hide ? ((price1).toFixed(6) +" BTC ≈ $"+ (price2).toFixed(2) ) : "********************" } `}</h5>
                    </div>
                   
                    
                </div>
                </div>

                <div className="dashboard-margin table-responsive">
                  <input type="text" className={`tableinput`} placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)}/>
                <MDBDataTableV5
                hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={datatable}
                pagingTop
                searchBottom={false}
                />
                </div>
            </div>
        </div>
        <TransferModal transfershow={transfershow} transfer_close={transfer_close}/>

        </>
    )

}
export default Spot;