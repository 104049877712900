import axios from "axios";
import { fetchUserData } from "../store/actions";


const fetchUser = async (dispatch) =>{    
    try {
        const data=await axios.get(`${process.env.REACT_APP_API_URL}/api/user`,{
            headers:{
                Authorization:`Bearer ${localStorage.getItem("token")}`
            }
          })
          if(data.data){
              dispatch(fetchUserData(data.data))
          }
    } catch (error) {
        console.log(error)
    }
}

export {fetchUser}