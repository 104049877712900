import { Row,Col, Tab, Tabs, Table, Modal, Form } from "react-bootstrap"
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from "react";
import axios from "axios";
import { fetchUser } from "../../Services/UserCentricService";
import { useDispatch } from "react-redux";




function PhoneModal(props){ 

    const dispatch = useDispatch()
    const [phone, setPhone] = useState("")
    const [otp, setOtp] = useState("")
    const [phoneError,setPhoneError] = useState("")
    const [flag,setFlag] = useState(false)
    const [message,setMessage] = useState("")
    const [otpError,setOtpError] = useState("")


    const sendCode = async () =>{
        if(phone){
            setPhoneError("")
            try {
                let data={
                    phone:phone
                }
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/send-otp`,data,{
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token"),
                    }
                })            
                console.log("res :",res)
                setFlag(true)
                setOtpError("")

            } 
            catch (error) {
                // console.log(error.response.data)
                setPhoneError(error.response.data.message)

            }
        }
        else{
            setPhoneError("Please enter valid phone!")
        }
    }


    const verifyCode = async (e) =>{
        e.preventDefault()
        if(phone && otp){
            try {
                let data={
                    phone:phone,
                    otp:otp
                }
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/2faverify-otp`,data,{
                headers:{
                    "Authorization": "Bearer "+ localStorage.getItem("token"),
                }
                })        
                console.log(res)
                setFlag(false)
                if(res.data.status=="FAILED"){
                    setOtpError(res.data.message)
                }
                else{

                setMessage(res.data.message)
                fetchUser(dispatch)

                setTimeout(()=>{
                    setPhone("")
                    setOtp("")
                    props.phone_close()
                },3000)
                }


            } catch (error) {
                console.log(error.response)
            }
        }
    }



    return (
        <>
            <Modal show={props.show} onHide={props.phone_close} centered className="security-verify-modal">
                    
                    <Modal.Body className="box-bg">

                    <i class="fa-solid fa-xmark" onClick={props.phone_close}></i>

                    <div className="modal-title text-center">
                        <h3>Phone Verification</h3>
                    </div>
                    <Form>
                        <Form.Group className="mt-5" controlId="phone">
                        <Form.Label className="text-left">Phone Number</Form.Label>
                        <div className="d-flex">
                        {/* <Form.Control type="password" className="form-control country-code" />
                        <input type="phone" className="form-control" value={mynumber} onChange={(e) => { 
                       setnumber(e.target.value) }}/> */}
                        <PhoneInput
                        country={'us'}
                        enableSearch={true}
                        className="form-control"
                        value={phone}
                        onChange={(e) => {
                            setPhoneError("")
                            setPhone(e)}}
                        />
                        </div>
                        </Form.Group>
                        {
                         phoneError ? <p className="validation_error">{phoneError}</p> : ""
                        }
                        <Form.Group className="mt-3 " controlId="password">
                        <Form.Label>Phone Number Verification Code</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="text"  value={otp} onChange={(e)=> {e.target.value.length<=6 && setOtp(e.target.value)}} />
                        <p class="form-icon text-black pointer" onClick={sendCode}>Get Code</p>
                        </div>
                    </Form.Group>
                    {
                        otpError ? <p className="mt-1 f-bold validation_error">{otpError} </p> : ""
                    }
                    { 
                        flag ?  <p className="mt-1 f-bold" style={{color:"green"}}> Enter 6 digits code sent to your email</p> : ""
                    }

                    {
                        message ? <p className="mt-3" style={{textAlign:"center",color:"green"}}> {message}</p> : ""
                    }

                    <button className="mt-5 w-100 custom-icon-btn primary-btn" onClick={verifyCode}>Continue</button>

                    </Form>
                  


                    </Modal.Body>
            </Modal>
        </>
    )
}
export default PhoneModal;