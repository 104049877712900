import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap"
import axios from "axios";


function KYCLevel3Modal(props){
    const [preview3,setPreview3] = useState('');
    const [video, setVideo] = useState()
    const [error, setError] = useState()
    const[disabled, setDisabled] = useState(false)



    useEffect(() => {
        if(!props.check1){
            setError("level 1 kyc is not created yet")
            setDisabled(true)
        }
        else if(!props.check2){
            setError("level 2 kyc is not created yet")
            setDisabled(true)
        }
        else{
            setError("")
            setDisabled(false)
        }
    }, [props.kyclevel3show])

     const handleChangeImage3 = (e) => {
        let ext=e.target.files[0].name.split(".").pop()
        if(ext == "mp4" || ext =="3gp"){
            if(e.target.files[0]){
                setPreview3({[e.target.name]: URL.createObjectURL(e.target.files[0])})
                setVideo(e.target.files[0])
            }
            if(!props.check1){
                setError("level 1 kyc is not created yet")
            }
            else if(!props.check2){
                setError("level 2 kyc is not created yet")
            }
            else{
                setError("")
            }
        }    
        else{
            setError("Video Format not Supported! Try Above formats")
        }  
     }

     const VideoVerification = async (e) => {
        try {
            e.preventDefault()
            if(preview3 !== ""){
                console.log("hello")

                const formData = new FormData()
                formData.append("video", video)

                console.log("formData", formData)
               
                const identity = await axios.post(`${process.env.REACT_APP_API_URL}/api/kyc-level3`, formData,
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token"),
                        'content-type': 'multipart/form-data'
                    }
                })

                localStorage.setItem("kyc3btn_disable", true)

                console.log("identity", identity)
                setDisabled(true)
                props.kyclevel3_close()
            }
            else{
                setError("Please Select a Video")
            }
        }

        catch (error) {
            setError(error.response.data.message)
            setDisabled(true)
            console.log(error.response.data.message)
        }
    } 

    return (<>
         <Modal show={props.kyclevel3show} onHide={props.kyclevel3_close} centered className="security-verify-modal">
                    
                

                    <Modal.Body className="box-bg">

                    {/* <i class="fa-solid fa-xmark" onClick={props.kyc_close}></i> */}

                    <form onSubmit={VideoVerification}>
                    <div className="modal-title text-center">
                            <h3>Identity Verification</h3>
                    
                    </div>
                        <div className="mt-3">
                        <span className="f-bold ">30 second live video verification</span>
                        </div>

                       <div className="upload-box">
                          
                           <input type="file" style={{display:'none'}} name="back" accept="video/*" id="back" onChange={(e)=>handleChangeImage3(e)}/>
                           <label className="upload-icon" for="back">
                           {
                               preview3 != "" ?
                            //    <video src={preview3['back']} alt="upload image" width={300} height={130} className="upload-img pointer" controls/>
                               <i class="fa-solid fa-video"></i>
                               :
                               <div>
                               <i class="fa-solid fa-video"></i>
                               </div>
                           }
                           </label>
                        </div>
                        <div className="mt-3">
                        <span className="">File size must be between 1MB and 10MB in ..mp4/3gp format.</span>
                        </div>
                    
                    <div className="text-center">

                    <div style={{paddingTop:"1rem", color:"red"}}>{error}</div>
               
                        <div className="d-flex mt-5">
                        <button disabled={disabled}  className="w-100 custom-icon-btn primary-btn" type="submit" >Finish</button>
                        </div>
                    </div>
                    </form>

                  
                    

                    </Modal.Body>
        
            </Modal>
    </>)
}

export default KYCLevel3Modal;