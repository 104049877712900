import axios from "axios";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchUser } from "../../Services/UserCentricService";


function Welcome_DashB(props){

    const dispatch = useDispatch()
    const {state}=useLocation()
    const fetchToken = async () =>{
        try {
            const id = state.id
            let data={id:id}
            const token_data = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user`,data)  
            let token=token_data.data.token  
            if(token){
                localStorage.setItem("token",token)     
                fetchUser(dispatch)
            }     
            
        } catch (error) {
            console.log(error)
        }

    }
    // /auth/user/:id
    useEffect(() => {
        fetchToken()
    }, [])
    


    return(

        <>
        
         {props.header}

        <Container >
            <Link to={'/dashboard'} className="anchor flex-end">Dashboard</Link>
            <div className="banner-section">

                <div className="heading-border text-center">
                    <h1 className="dual-heading">Welcome To <span>PayRexa</span></h1>
                    <hr/>
                    <p>Start your crypto journey with a partner you can trust</p>
                </div>

            </div>


            <div className="text-center welcome-sc">
                <h1 className="dual-heading-sm">Get Started With Your Crypto Journey</h1>

                <div>

                    <img src={require("../../assets/images/welcome.png").default}/>

                </div>

                <a class="custom-btn primary-btn" href="#">Learn More <i class="fa-solid fa-arrow-right"></i></a>

            </div>





        </Container>

       

        </>

    )

}

export default Welcome_DashB;