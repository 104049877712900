import axios from "axios"
import { useState } from "react"
import { Modal, Form } from "react-bootstrap"


function ChangePasswordModal(props){

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [message,setMessage] = useState("")
    const [error,setError] = useState("")
    const [confirmPassError,setConfirmPassError] = useState("")


    const changePassword = async (e) =>{
        e.preventDefault()
        try {
            if(newPassword===confirmPassword){
                setError("")
                let data={
                    oldPassword,
                    newPassword
                }
                let headers={
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                      }
                }
                const res= await axios.post(`${process.env.REACT_APP_API_URL}/api/change-password`,data,headers)
                console.log(res)
                setMessage(res.data.message)
                setNewPassword("")
                setOldPassword("")
                setConfirmPassword("")

            
                setTimeout(()=>{
                    setMessage("")
                    props.ch_pass_close()},2000)

            }            
        } catch (error) {
            console.log("error :",error.response.data.message)
            setError(error.response.data.message)
        }
        

    } 

    return (<>
         <Modal show={props.show} onHide={props.ch_pass_close} centered className="security-verify-modal">
                    
                

                    <Modal.Body className="box-bg">

                    <i class="fa-solid fa-xmark" onClick={props.ch_pass_close}></i>

                    <div className="modal-title text-center">
                        <h3>Change Password</h3>
                    </div>

                    <Form>
                    <Form.Group className="mt-3 " controlId="old">
                        <Form.Label>Old Password</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="password" value={oldPassword} onChange={(e)=>{
                            error && setError("")
                            return setOldPassword(e.target.value)}} />
                        <i class="fa fa-eye-slash form-icon pointer"></i>
                        </div>
                    </Form.Group>
                    {
                        error ? <p className="validation_error" >{error}</p> : ""
                    }
                        <Form.Group className="mt-3 " controlId="new">
                        <Form.Label>New Password</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="password" value={newPassword} onChange={(e)=>{
                            (confirmPassword.length>0 && confirmPassword != e.target.value) && setConfirmPassError("Confirm password is not same!")
                            e.target.value==confirmPassword && setConfirmPassError("")
                              return setNewPassword(e.target.value)}} />
                        <i class="fa fa-eye-slash form-icon pointer"></i>
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-3 " controlId="confirm">
                        <Form.Label>Confirm New Password</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="password" value={confirmPassword} onChange={(e)=>{
                            (newPassword != e.target.value) && setConfirmPassError("Confirm password is not same!")
                            newPassword==e.target.value && setConfirmPassError("")
                            return setConfirmPassword(e.target.value)}}/>
                        <i class="fa fa-eye-slash form-icon pointer"></i>
                        </div>
                    </Form.Group>
                    {
                        confirmPassError ? <p className="validation_error" >{confirmPassError}</p> : ""
                    }
                    {
                        message ? <p className="mt-3" style={{textAlign:"center",color:"green"}}>{message}</p> : ""
                    }
                    <div className="text-center">
                    <button className={`${message ? "mt-2" :"mt-5"} w-100 custom-icon-btn primary-btn`} onClick={changePassword}>Continue</button>
                    </div>
                    </Form>
                   
                  
                    

                    </Modal.Body>
        
            </Modal>
    </>)
}

export default ChangePasswordModal;