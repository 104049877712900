import React, { useEffect, useState } from 'react';
import { firebase, auth } from '../components/FirebaseOtp';
import { Col, Container, Form, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/images/formlogo.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import SliderCaptcha from '@slider-captcha/react';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import axios from "axios"

import { useDispatch, useSelector } from 'react-redux';
import { addLoginId, initiateSettings } from "../store/actions";
import { fetchUser } from '../Services/UserCentricService';
import { browserName, browserVersion, isBrowser, isMobile  } from "react-device-detect";
import { getSettings } from '../Services/SettingsService';


function verifiedCallback(token) {
    // console.log('Captcha token: ' + token);
  }
function Login(props){
         const dispatch = useDispatch()

        const mystate=useSelector((state)=> state.userReducer)

        // Inputs
        const [mynumber, setnumber] = useState("");
        const [otp, setotp] = useState('');
        const [show, setshow] = useState(false);
        const [final, setfinal] = useState('');
        const [ispasswordshow, setIspasswordshow] = useState(false);

        // Mutahir Commet
        const [gmail, setGmail] = useState()
        const [password, setPassword] = useState()
        const [phone,setPhone] = useState()
        const [error, setError] = useState()

        const getClientData = async () => {
            const res = await axios.get('https://geolocation-db.com/json/')   
            const userAgent=navigator.userAgentData.platform
            const logonData={
                location:`${res.data.city}, ${res.data.country_name}`,
                ip_address:res.data.IPv4,
                platform: userAgent ? userAgent :"Mobile",
                device:`${browserName} V${browserVersion}`
            }
            return logonData
          }
       
    
        // useEffect(()  => {
        //     console.log("first :",mystate)
        //   }, [mystate])
    

        const userSignIn = async () => {
           try{
            let data;
            loginstatus == "email"  ? 
            data={
                email: gmail,
                password: password
            } : 
            data={
                phone: phone,
                password: password
            }

            // console.log(data)
            const signIn = await axios.post(`${process.env.REACT_APP_API_URL}/api/signin`, data)

            const u = signIn.config.data
            const token = signIn.data.token

            if(token) {
                localStorage.setItem("token", token);
                fetchUser(dispatch)
               
                const loginData=await getClientData()
                const {data}=await axios.post(`${process.env.REACT_APP_API_URL}/api/device-login`,loginData,
                { 
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                dispatch(addLoginId(data.id))

                const settings=await getSettings()
                await dispatch(initiateSettings(settings))
                navigate('/')
            // console.log("token", token)
            }

            
           }
           catch(error) {
            setError(error.response.data.message)
           }
        }
      
        // Sent OTP
        const signin = (e) => {
            e.preventDefault()
            // console.log(mynumber)
            // if (mynumber === "" || mynumber.length < 10) return;
      
            let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            auth.signInWithPhoneNumber("+"+mynumber, verify).then((result) => {
                setfinal(result);
                alert("code sent")
                setshow(true);
            })
                .catch((err) => {
                    alert(err);
                    window.location.reload()
                });
        }
      
        // Validate OTP
        const ValidateOtp = () => {
            if (otp === null || final === null)
                return;
            final.confirm(otp).then((result) => {
                // success
            }).catch((err) => {
                alert("Wrong code");
            })
        }

    const [loginstatus,setLoginstatus] = useState('email');
    const navigate = useNavigate()
    const responseGoogle = (response) => {
        // console.log(response);
      }
    const SignIn = (e)=>{
        e.preventDefault();
        
        // navigate('/')
    }
    return (
        <>
    {props.header}
       
        <Container>
    
        <div className="form-section-margin">
        <Row className="justify-content-center">
            <Col lg="6" sm="12" >
            <main className="form-section">
            <Row>
                <Col lg="12">
                <div className="form">
                <Form onSubmit={(e) => {
				SignIn(e);
				}}>
                <div className="head-section">
                <img src={Logo}/>
                <h2>Account Login</h2>
                </div>
                <div className="d-flex justify-content-center form-ul">
                    <a href="javascript::void()" className={loginstatus == "email" ?"active":""} onClick={()=>setLoginstatus("email")}>Email</a>
                    <a className="form-slash"><i class="fa-solid fa-slash"></i></a>
                    <a href="javascript::void()" className={loginstatus == "phone" ?"active":""} onClick={()=>setLoginstatus("phone")}>Phone</a>
                </div>
                
                {
                    loginstatus == "email" ? <>
                    <Form.Group className="" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" onChange={(e) => setGmail(e.target.value)} />
                    </Form.Group>
                    </>
                    :<>
                    <Form.Group className="" controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <div className="d-flex">
                        {/* <PhoneInput
                        country={'pk'}
                        enableSearch={true}
                        value={mynumber} 
                        onChange={(e) => setnumber(e) }
                        className="form-control"/> */}
                    <PhoneInput
                        country={'pk'}
                        enableSearch={true}
                        value={phone}
                        onChange={(e)=>{
                            setPhone(e)
                        }}
                        className="form-control"/>
                        </div>
                    </Form.Group>
                    </>
                }
                <Form.Group className="mt-3 " controlId="password">
                        <Form.Label>Password</Form.Label>
                        <div className='position-relative'>
                        <Form.Control onChange={(e) => setPassword(e.target.value)} type={ispasswordshow == false ? "password" : "text"} />
                        {
                            ispasswordshow == false ?<>
                        <i class="fa fa-eye-slash form-icon pointer" onClick={(e)=>setIspasswordshow(true)}></i>
                        </>
                        :<>
                        <i class="fa fa-eye form-icon pointer" onClick={(e)=>setIspasswordshow(false)}></i>
                        </>
                        }
                        </div>
                </Form.Group>
                <div className='mt-2'>
                {/* <div id="recaptcha-container"></div> */}
                <div style={{color:"red"}}>
                    {error}
                </div>
         
                </div>
                <div className="mt-5">
                <button onClick={userSignIn} className="w-100 custom-btn primary-btn" type="submit" >Sign in</button>
                </div>
                <div className="d-j-flex">
                

                <div className="d-grid form-ul text-center justify-content-center w-100">
                
                <Link to={'/register'}>Register Now</Link>
                <Link to={'/forgot-password'}>Forgot password?</Link>
                </div>
                </div>
            </Form>
                </div>
                </Col>
            </Row>
            
            </main>
            </Col>
        </Row>
        </div>
        </Container>
        </>
    )
}
export default Login