import { useState } from "react";


function Toggleswitch(props){

    // const [isToggled, setIsToggled] = useState(false);
    const onToggle = () => {
        props.onClick()
        props.setState(!props.state)
    }

    return (
        <>
            <label className="toggle-switch">
                <input type="checkbox" checked={props.state} onChange={onToggle} />
                <span className="switch" />
            </label>
        </>
    )
}
export default Toggleswitch;