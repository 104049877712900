import axios from "axios";
import { useEffect, useState } from "react";
import {Form, Row,Col,Table} from "react-bootstrap";
import { Link } from "react-router-dom";


function OpenOrder(props){

   
    // sidebar.addEventListener("click", function() { 
       
    // });
    // useEffect((e)=>{
    //     var myMovie = document.getElementsByClassName("order-head-mobile")[0];
    //     myMovie.addEventListener('click', function (event) {
    //     var sidebar = document.getElementsByClassName("mobile-left-side-bar")[0];
    //         document.getElementsByClassName("dashboard-main")[0].classList.add("overlay")
    //         sidebar.classList.remove("close-order-sidebar")
    //         sidebar.classList.add("show-order-sidebar")
    //     });
      
        
    // },[])
    // useEffect((e)=>{
    //         var overlay = document.getElementsByClassName("overlay")[0];
    //         overlay.addEventListener('click', function (event) {
    //             var sidebar = document.getElementsByClassName("mobile-left-side-bar")[0];
    //             document.getElementsByClassName("dashboard-main")[0].classList.remove("overlay")
    //             sidebar.classList.remove("show-order-sidebar")
    //             sidebar.classList.add("close-order-sidebar")
    //         }); 
    // })

    const [userBuyOrders, setUserBuyOrders] = useState([])
    const [userSellOrders, setUserSellOrders] = useState([])

    // Get All Open Orders of Particular User in Spot Trade
    const get_open_orders = async () => {
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_open_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setUserBuyOrders(orders.data.buy_orders)
        setUserSellOrders(orders.data.sell_orders)
    }
    useEffect(() => {
        get_open_orders()
    }, [])
    
    // Get Limit Open Orders of Particular User in Spot Trade
    const fetchLimit = async () =>{
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_open_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setUserBuyOrders(orders.data.buy_orders.filter(order=>order.is_limit))
        setUserSellOrders(orders.data.sell_orders.filter(order=>order.is_limit))
    }

    // Get Stop Limit Open Orders of Particular User in Spot Trade
    const fetchStopLimit = async () =>{
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_open_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setUserBuyOrders(orders.data.buy_orders.filter(order=>order.is_stop_limit))
        setUserSellOrders(orders.data.sell_orders.filter(order=>order.is_stop_limit))
    }

    const setDate = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString() + " " + d.toLocaleTimeString();
        return date
    }
    return(
        <>
            {props.header}

            <div className="dashboard-section order-body-width">

                {props.sidebar}

                <div className="dashboard-main">

                <div className="normal-box big-bg order-head-mobile" >
                    <div>
                    <span>Spot</span>
                    <h3>Open Orders</h3>
                    </div>
                    <i class="fa-solid fa-angle-right open-order-sidebar"></i>
                </div>
                <div className="dashboard-margin">
                    <div className="order-btn-flex-resp four-btns">
                    <button className="custom-btn primary-btn" onClick={get_open_orders}>All</button>
                    <button className="custom-btn secondary-btn" onClick={fetchLimit}>Limit Order</button>
                    <button className="custom-btn secondary-btn" onClick={fetchStopLimit}>Stop-Limit Order</button>
                    <button className="custom-btn secondary-btn">Limit-Maker</button>
                    </div>
                </div>
                <div className="mt-3 table-responsive">
                <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Filled</th>
                            <th>Total</th>
                            <th>Trigger Conditions</th>
                            
                        </tr>
                        </thead>
                        <tbody>
                        {
                                userSellOrders.length !=0 &&
                                userSellOrders.map(order=>{
                                    return <tr key={order._id}>
                                        <td>{setDate(order.createdAt)}</td>
                                        <td>--------</td>
                                        <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit")}</td>
                                        <td><span className="text-lightred">Sell</span></td>
                                        <td>{(order.is_limit && order.price) || (order.is_stop_limit && order.limit)}</td>
                                        <td>{order.order_amount}</td>
                                        <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)  * 100)/100}%</td>  
                                        {/*  100)/100  <- final this is for max 2 decimal result */}
                                        <td>{(order.total).toFixed(3)}</td>
                                        <td>{(order.is_limit && "----------") || (order.is_stop_limit && "<="+ order.stop)}</td>
                                    </tr>
                                })
                            }
                             {
                                userBuyOrders.length !=0 &&
                                userBuyOrders.map(order=>{
                                    return <tr key={order._id}>
                                        <td>{setDate(order.createdAt)}</td>
                                        <td>--------</td>
                                        <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit")}</td>
                                        <td><span className="text-lightgreen">Buy</span></td>
                                        <td>{(order.is_limit && order.price) || (order.is_stop_limit && order.limit)}</td>
                                        <td>{order.order_amount}</td>
                                        <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)*10000)/10000}%</td>
                                        {/* Math.trunc(order.amount*10000)/10000} */}
                                        <td>{(order.total).toFixed(3)}</td>
                                        <td>{(order.is_limit && "----------") || (order.is_stop_limit && ">="+ order.stop)}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                </div>
             
            
            </div>

          

        </>

    )

}

export default OpenOrder