import { useEffect, useState } from "react";
import { Container,Row,Col,Form,Dropdown,Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BtcImage from "../../assets/images/btc.svg";
import ResetImage from "../../assets/images/spt-reset.png"
import UnlockImage from "../../assets/images/spt-unlock.png"
import PhoneImage from "../../assets/images/spt-phone.png"
import EmailImage from "../../assets/images/spt-email.png"
import DepositImage from "../../assets/images/spt-deposit.png"
import MissingImage from "../../assets/images/spt-missing.png"
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

import TradeRuleModal from "../../components/modals/TradeRule";
import TransferModal from "../../components/modals/Transfer";
import OrderIconOne from '../../assets/images/order-icon1.png'
import OrderIconTwo from '../../assets/images/order-icon2.png'
import OrderIconThree from '../../assets/images/order-icon3.png'
import axios from "axios";
import LessAmountError from "../../components/modals/LessAmountError";

function SpotTrade(props){

    const [trade,setTrade] = useState('market');
    const [tradetype,setTradetype] = useState('buy')
    const [order,setOrder] = useState("open");
    const [spottype,setSpottype] = useState('limit');

    const [transfershow, setTransfershow] = useState(false);
    const transfer_close = () => setTransfershow(false);
    const transfer_show = () => setTransfershow(true);

    const [show, setShow] = useState(false);
    const [errorShow, setErrorShow] = useState(false);
    const trade_close = () => setShow(false);
    const trade_show = () => setShow(true);
    const error_close = () => setErrorShow(false);
    const error_show = () => setErrorShow(true);
    const [status,setStatus] = useState(false)
    
    // fields for orders
    const [price,setPrice]=useState("")
    const [amount,setAmount]=useState("")
    const [total,setTotal]=useState("") 

    const [buyOrders,setBuyOrders]=useState([])
    const [sellOrders,setSellOrders]=useState([])
    const [trades,setTrades]=useState([])
    const [userBuyOrders,setUserBuyOrders]=useState([])
    const [userSellOrders,setUserSellOrders]=useState([])
    const [price1,setPrice1] = useState(0)
    const [price2,setPrice2] = useState(0)
    const [availPrice1,setAvailPrice1] = useState(0)
    const [availPrice2,setAvailPrice2] = useState(0)
    
    const [userOrdersHistory,setUserOrdersHistory]=useState([])
    const [userTradesHistory,setUserTradesHistory]=useState([])

    const [fromDate,setFromDate] = useState("")
    const [toDate,setToDate]=useState("")
    const [marketPrice,setMarketPrice]= useState("")

    const [tradeVolumes, setTradeVolumes] = useState()

    // stop limit order extra fields
    const [stop, setStop] = useState("")
    const [limit, setLimit] = useState("")
    const [buyMarketPrice, setBuyMarketPrice] = useState()
    const [sellMarketPrice, setSellMarketPrice] = useState()
    let userid;


    const fetchWallets= async () =>{
        const wallets=await axios.get(`${process.env.REACT_APP_API_URL}/api/user_wallets`,
        { 
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
        )
        if(wallets.data.wallets.length>0){
            setPrice1(wallets.data.wallets[0].balance)
            setPrice2((wallets.data.wallets[1].balance))
    
            setAvailPrice1(wallets.data.wallets[0].available_balance)
            setAvailPrice2(wallets.data.wallets[1].available_balance)
        }

    }



//  Fetching data for component rendering

// consuming api for sell orders of order book
    const get_sell_orders = async () => {
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_sell_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setSellOrders(orders.data.orders)        
    }

// consuming api for Buy orders of order book
    const get_buy_orders = async () =>{
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_buy_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setBuyOrders(orders.data.orders)
    }
    

// consuming api for Trades of Market Trades in spot Trading
    const get_trades = async () => {
        const trades=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_trades`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })

        setTrades(trades.data.transactions)
        fetchTradeVolumes()
    }

    // fetch open orders of particular user
    const get_open_orders = async () => {
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_open_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setUserBuyOrders(orders.data.buy_orders)
        setUserSellOrders(orders.data.sell_orders)
    }

    // Consume api of Orders history of particular user
    const get_orders_history = async () => {
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_orders_history`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
       
        setUserOrdersHistory([orders.data.sell_orders,orders.data.buy_orders])
    
    }

    // filters according to date ranges
    const fetchfilterOrders = async (api) => {
        try{
            const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/${api}`,
            {
                headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }})
            setUserOrdersHistory([orders.data.sell_orders,orders.data.buy_orders])
            // console.log(orders.data.orders)
        }
        catch(e) {
            console.log(e)
        }
    }   


    const fetchCustomDateOrders = async () =>{
        if(fromDate!="" && toDate!=""){
            try{
                const orders=await axios.post(`${process.env.REACT_APP_API_URL}/api/get_custom_user_orders`,{
                    from:fromDate,
                    to:toDate
                },
                {
                    headers:{
                    "Authorization": "Bearer "+ localStorage.getItem("token")
                }})
                setUserOrdersHistory([orders.data.sell_orders,orders.data.buy_orders])
            }
            catch(e) {
                console.log(e)
            }
        }
        else{
            alert("Please select From date and To date!")
        }
    }

    // getting data of multiple traders
    const updateFromMultipeTraders = (trades) =>{
        userid=trades.data.user_id
        let buy_multiple_trade=trades.data.sell_trades.map((trade)=>{
            if(trade.sell_user_id.length>1){
                trade.amount=0
                trade.sell_user_id.map((id,i)=>{
                    if(id===userid){
                        trade.amount+=trade.sell_id[i].amount
                    }
                })
            }
        })

        
        let sell_multiple_trade=trades.data.buy_trades.map((trade)=>{
            if(trade.buy_user_id.length>1){
                trade.amount=0
                trade.buy_user_id.map((id,i)=>{
                    if(id===userid){
                        trade.amount+=trade.buy_id[i].amount
                    }
                })
            }
        })
    }

    //consuming api of tradehistory of particular user
    const fetchUserTrades = async () =>{
        setOrder("tradehistory")
        // setUserTradesHistory
        try{
            const trades=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_trades_history`,
            {
                headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }})
            updateFromMultipeTraders(trades)
            setUserTradesHistory([trades.data.buy_trades,trades.data.sell_trades])
        }
        catch(e) {
            console.log(e)
        }
    }

    // consuming api of day week and month trades of particular user.
    const fetchfilterTrades = async (api) => {
        try{
            const trades=await axios.get(`${process.env.REACT_APP_API_URL}/api/${api}`,
            {
                headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }})
            updateFromMultipeTraders(trades)

            setUserTradesHistory([trades.data.buy_trades,trades.data.sell_trades])
            userid=trades.data.user_id

        }
        catch(e) {
            console.log(e)
        }
    }

    // consuming api of Custom trades of particular user.
    const fetchCustomDateTrades = async () =>{
        console.log(fromDate)
        console.log(toDate)
        if(fromDate!="" && toDate!=""){
            try{
                const trades=await axios.post(`${process.env.REACT_APP_API_URL}/api/get_custom_user_trades`,{
                    from:fromDate,
                    to:toDate
                },
                {
                    headers:{
                    "Authorization": "Bearer "+ localStorage.getItem("token")
                }})
                updateFromMultipeTraders(trades)

                setUserTradesHistory([trades.data.buy_trades,trades.data.sell_trades])
                userid=trades.data.user_id

            }
            catch(e) {
                console.log(e)
            }
        }
        else{
            alert("Please select From date and To date!")
        }
    }


    
    const fetchTradeVolumes = async () =>{
        try{
            const TradeVolumes=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_spot_volumes`,
            {
                headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }})
           setTradeVolumes(TradeVolumes.data)
        }
        catch(e) {
            console.log(e)
        }
    } 

    const makeBuyOrder = async (e) => {
        e.preventDefault()
        if(spottype=="market" && availPrice2<price){
            // alert("Your Amount is less then Order Total")
            error_show()
            return 
        }
        if(availPrice2<parseFloat((price*amount).toFixed(10))){
            // alert("Your Amount is less then Order Total")
            error_show()

            return 
        }
        
        if(spottype=="limit"){
            if(price>0 && amount>0){
                setPrice("")
                setAmount("")

                await axios.post(`${process.env.REACT_APP_API_URL}/api/buy_order`,
                {
                    price:Number(price),
                    amount:Number(amount),
                    total:parseFloat((price*amount).toFixed(10)),
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_limit:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                })

            }
            else{
                // alert("Fill the Fields")
                error_show()

            }
        }

        if(spottype=="market"){
            if(price>0 && amount>0){
                setPrice("")
                setAmount("")
                await axios.post(`${process.env.REACT_APP_API_URL}/api/buy_order`,
                {
                    total:Number(price), // as frontend does calculation
                    amount:Number(amount),
                    price:price/amount, // for finding price 
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_market:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )

            }
            else{
                // alert("Fill the Fields")      
                error_show()
      
            }
        }

        if(spottype=="stop-limit"){
            if(limit>0 && amount>0){

                setStop("")
                setLimit("")
                setAmount("")    
                await axios.post(`${process.env.REACT_APP_API_URL}/api/buy_order`,
                {
                    stop:Number(stop),
                    limit:Number(limit),
                    amount:Number(amount),
                    total:Number(limit*amount),
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_stop_limit:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )
      
            }
            else{
                // alert("Fill the Fields")
                error_show()

            }
        }

        if(spottype=="oco"){
            if(limit>0 && amount>0 && price>0 && stop>0){

                setPrice("")
                setStop("")
                setLimit("")
                setAmount("")          
                await axios.post(`${process.env.REACT_APP_API_URL}/api/buy_order`,
                {
                    price:Number(price),
                    stop:Number(stop),
                    limit:Number(limit),
                    amount:Number(amount),
                    total:Number(limit*amount),
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_oco:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )
            }
            else{
                // alert("Fill the Fields")
                error_show()

            }
        }

         setTimeout(async ()=>{
            await get_open_orders()
        },2500)
        
    }

    const makeSellOrder = async (e) =>{
        e.preventDefault()

        if(availPrice1<amount){
            // alert("Your Amount is less then Order Total")
            error_show()

            return 
        }
        if(spottype=="limit"){
            if(price>0 && amount>0){
                setPrice("")
                setAmount("")
                await axios.post(`${process.env.REACT_APP_API_URL}/api/sell_order`,
                {
                    price:Number(price),
                    amount:Number(amount),
                    total:parseFloat((price*amount).toFixed(10)),
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_limit:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                })
            }
            else{
                // alert("Fill the Fields")
                error_show()

            }
        }
    
    
        if(spottype=="market"){
            if(price>0 && amount>0){
                
                setPrice("")
                setAmount("")
                await axios.post(`${process.env.REACT_APP_API_URL}/api/sell_order`,
                {
                    total:Number(price), // as frontend does calculation
                    amount:Number(amount),
                    price:price/amount,
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_market:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )


            }
            else{
                // alert("Fill the Fields")
                error_show()

            }
        }

        if(spottype=="stop-limit"){
            if(limit>0 && amount>0 && stop>0){
                setStop("")
                setLimit("")
                setAmount("")

                await axios.post(`${process.env.REACT_APP_API_URL}/api/sell_order`,
                {
                    stop:Number(stop),
                    limit:Number(limit),
                    amount:Number(amount),
                    total:Number(limit*amount),
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_stop_limit:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )

        

            }
            else{
                // alert("Fill the Fields")
                error_show()

            }
        }


        if(spottype=="oco"){
            if(limit>0 && amount>0 && price>0 && stop>0){
                setPrice("")
                setStop("")
                setLimit("")
                setAmount("")          
                await axios.post(`${process.env.REACT_APP_API_URL}/api/sell_order`,
                {
                    price:Number(price),
                    stop:Number(stop),
                    limit:Number(limit),
                    amount:Number(amount),
                    total:Number(limit*amount),
                    trade_coin_id:"56789",
                    base_coin_id:"89234",
                    is_oco:true,
                    category:1
                },
                {
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                }
                )
                        
           
            }
            else{
                // alert("Fill the Fields")
                error_show()
            }
        }
        setTimeout(async ()=>{
            await get_open_orders()
        },2500)
    }


    useEffect(() => {
            if(localStorage.getItem("token")){
                get_open_orders()
                get_sell_orders()
                
                let sell_interval=setInterval(get_sell_orders,2000)
                let orders_interval=setInterval(get_open_orders,2000)
                return () =>{
                    clearInterval(sell_interval)
                    clearInterval(orders_interval)           
               }
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("token")){

            get_buy_orders()
            let buy_interval=setInterval(get_buy_orders,2000)
            return () =>{
                clearInterval(buy_interval)
            }
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("token")){
            fetchWallets()
            get_trades()
            let trade_interval=setInterval(get_trades,2000)    
            let wallets_id=setInterval(fetchWallets,2000)    
            return () => {
                clearInterval(trade_interval)
                clearInterval(wallets_id)
            }
        }
    }, [])

    useEffect(() => {
        setPrice("")
        setAmount("")
        setStop("")
        setLimit("")
        
    }, [spottype])
    
    useEffect(() => {
        setToDate("")
        setFromDate("")
         
    }, [order])
    
    


    const findMarketPrice = () =>{
      
        if(buyOrders.length!=0 && sellOrders.length!=0){
            let buy_max=buyOrders[0].price
            setBuyMarketPrice(buy_max)
            let sell_min=sellOrders[sellOrders.length-1].price  
            setSellMarketPrice(sell_min)
            setMarketPrice(buyOrders.length > sellOrders.length ? buy_max : sell_min)    
        }
         
    }
    useEffect(() => {
        findMarketPrice()
    }, [buyOrders,sellOrders])
    

    const setTime = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString();
        return date
    }

    const setDate = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString() + " " + d.toLocaleTimeString();
        return date
    }


    const deleteOrder = async (id,type) => {
        if(type=="buy"){
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_buy_order/${id}`,
            {
                headers:{
                    Authorization:`Bearer ${localStorage.getItem("token")}`
                }
            })
        }
        else{
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_sell_order/${id}`,
            {
                headers:{
                    Authorization:`Bearer ${localStorage.getItem("token")}`
                }
            })
        }
    }


    const deleteAllOrders = async () =>{
        if(userBuyOrders.length!=0 || userSellOrders.length!=0){
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_all_userorders`,
            {
                headers:{
                    Authorization:`Bearer ${localStorage.getItem("token")}`
                }
            })
        }
    }


    return (
        <div className="black">

        {props.header}
           
            <div className="dashboard-main container-fluid">
        
                <Row className="mt-3 tradingview-main-responsive">
                    <Col xxl={8} xl={8} lg={8} className="custom-col-tradeview chart-border">
                    <div className="spot-trade-top-second">
                        <div className="spot-trade-left">
                            <div className="first">
                                <div className="dropdown-coins">
                                <h4>BTC</h4>
                                <i class="fa-solid fa-caret-down"></i>
                                <div className="all-coin-list">
                                <input className="search w-100 mb-2" placeholder="Search"/>
                                <ul className="ul">
                                    <li className="trade-li li">
                                        <Link to={'#'} className="a"><span>BTC</span><i class="fa-solid fa-caret-down"></i></Link>
                                        <ul>
                                            <li>
                                                <Link to={"#"}>BTC</Link>
                                            </li>
                                            <li>
                                                <Link to={"#"}>BTC</Link>
                                            </li>
                                            <li>
                                                <Link to={"#"}>BTC</Link>
                                            </li>
                                            <li>
                                                <Link to={"#"}>BTC</Link>
                                            </li>
                                            <li>
                                                <Link to={"#"}>BTC</Link>
                                            </li>
                                            <li>
                                                <Link to={"#"}>BTC</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="trade-li li">
                                        <Link to={'#'} className="a">BTC</Link>
                                    </li>
                                    <li className="trade-li li">
                                        <Link to={'#'} className="a">BTC</Link>
                                    </li>
                                    <li className="trade-li li">
                                        <Link to={'#'} className="a">BTC</Link>
                                    </li>
                                
                                </ul>
                                <div className="top-second">
                                <span>Pair</span>
                                <span>Price</span>
                                <span>Change<i class="fa-solid fa-right-left swap-pair"></i></span>
                                </div>
                                <div className="order-body right-order-one">
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightgreen">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightgreen">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightgreen">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightgreen">0.0004</span>
                                <span className="text-lightgreen">434.3233</span>
                            </div>
                            <div className="">
                                <span>4343.23</span>
                                <span className="text-lightred">0.0004</span>
                                <span className="text-lightred">434.3233</span>
                            </div>
                        </div>
                            </div>
                                </div>
                                <span>USDT</span>
                            </div>
                            <div>
                                <h5>{marketPrice}</h5>
                                <span>${marketPrice}</span>
                            </div>

                        </div>

                        <div className="spot-trade-right">

                        <div className="spot-trade-right-child">
                            <div>
                                <h5>24h High</h5>
                                <span>{tradeVolumes!=undefined ? tradeVolumes.max24 : "----"}</span>
                            </div>
                            <div>
                                <h5>24h Low</h5>
                                <span>{tradeVolumes!=undefined ? tradeVolumes.min24 : "----"}</span>
                            </div>
                        </div>
                            
                        <div className="spot-trade-right-child">
                            <div>
                                <h5>24h Volume(BTC)</h5>
                                <span>{tradeVolumes!=undefined ? tradeVolumes.sum_trade : "----"}</span>
                            </div>
                            <div>
                                <h5>24h Volume(USDT)</h5>
                                <span>{tradeVolumes!=undefined ? tradeVolumes.sum_base : "----"}</span>
                            </div>
                        </div>

                        </div>

                    </div>
                    <TradingViewWidget
                           width= "100%"
                           height= "441"
                           symbol= "BINANCE:BTCUSDT"
                           interval= "D"
                           timezone= "Etc/UTC"
                           theme= "dark"
                           style= "1"
                           locale= "en"
                           toolbar_bg= "rgb(22, 26, 30)"
                           enable_publishing= {false}
                           hide_side_toolbar= {false}
                           allow_symbol_change= {true}
                           hotlist= {false}
                           container_id= "tradingview_0cf12"
                    />
                    <div className="my-4">
                        <Row>
                        <Col lg={12}>
                        <div className="order-header">
                            <ul>
                                <li>
                                    <Link to={"#"} onClick={(e)=>setOrder("open")} className={order == "open" && "c-tab-active"}>
                                        Open Orders({userBuyOrders.length+userSellOrders.length})
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"#"} onClick={(e)=>{
                                            get_orders_history()
                                            setOrder("orderhistory")

                                        }} className={order == "orderhistory" && "c-tab-active"}>
                                        Order History
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"#"} onClick={() => fetchUserTrades()} className={order == "tradehistory" && "c-tab-active"}>
                                        Trade History
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"#"} onClick={(e)=>setOrder("funds")} className={order == "funds" && "c-tab-active"}>
                                        Funds
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {
                        order == "open" &&
                        <div className="table-responsive right-order-one-table">
                            <Table >
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Pair</th>
                                <th>Type</th>
                                <th>Side</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Filled</th>
                                <th>Total</th>
                                <th>Trigger Conditions</th>
                                <th> <span className="cancelorder" onClick={deleteAllOrders}>Cancel all</span></th>
                                
                            </tr>
                            </thead>
                            <tbody className="">
                            {
                                userSellOrders.length !=0 &&
                                userSellOrders.map(order=>{
                                    return <tr key={order._id}>
                                        <td>{setDate(order.createdAt)}</td>
                                        <td>-------</td>
                                        <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit") || order.is_market && "Market"}</td>
                                        <td><span className="text-lightred">Sell</span></td>
                                        <td>{(order.is_limit && order.price) || (order.is_stop_limit && order.limit)}</td>
                                        <td>{order.order_amount}</td>
                                        <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)  * 100)/100}%</td>  
                                        {/*  100)/100  <- final this is for max 2 decimal result */}
                                        <td>{(order.total).toFixed(3)}</td>
                                        <td>{(order.is_limit && "----------") || (order.is_stop_limit && "<="+ order.stop)}</td>
                                        <td><span className="cancelorder" onClick={()=>deleteOrder(order._id,"sell")}>cancel</span></td>

                                    </tr>
                                })
                            }
                         
                         {
                                userBuyOrders.length !=0 &&
                                userBuyOrders.map(order=>{
                                    return <tr key={order._id}>
                                        <td>{setDate(order.createdAt)}</td>
                                        <td>------</td>
                                        <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit")}</td>
                                        <td><span className="text-lightgreen">Buy</span></td>
                                        <td>{(order.is_limit && order.price) || (order.is_stop_limit && order.limit)}</td>
                                        <td>{order.order_amount}</td>
                                        <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)*10000)/10000}%</td>
                                        {/* Math.trunc(order.amount*10000)/10000} */}
                                        <td>{(order.total).toFixed(3)}</td>
                                        <td>{(order.is_limit && "----------") || (order.is_stop_limit && ">="+ order.stop)}</td>
                                        <td><span className="cancelorder" onClick={()=>deleteOrder(order._id,"buy")}>cancel</span></td>

                                    </tr>
                                })
                            }


                            </tbody>
                        </Table>
                        </div>
                        }
                        {
                            order == "orderhistory" && 
                            <>
                            <div className="spot-filter">
                                <div>
                                <button class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterOrders("get_today_user_orders")}>1 Day</button>
                                <button  class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterOrders("get_week_user_orders")}>1 Week</button>
                                <button  class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterOrders("get_month_user_orders")} >1 Month</button>
                                <button class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterOrders("get_3months_user_orders")}>3 Months</button>
                                </div>
                            <div className="from-to">
                            <div>
                            <span>From</span>
                            </div>
                            <div>
                                <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} className="trade-input"/>
                            </div>
                            <div>
                            <span>To</span>
                            </div>
                            <div>
                                 <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} className="trade-input"/>
                            </div>
                            </div>

                            <div>
                            </div>
                            <div>
                                <button  class="custom-btn-x-sm-sec btn-gray1" onClick={fetchCustomDateOrders}>Search</button>
                                <button  class="custom-btn-x-sm-sec btn-gray1" onClick={() => {
                                    setFromDate("")
                                    setToDate("")}}>
                                        Reset 
                                    </button>
                            </div>
                            </div>
                        <div className="table-responsive right-order-one-table">
                        <Table >
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Pair</th>
                                <th>Type</th>
                                <th>Side</th>
                                <th>Average</th>
                                <th>Price</th>
                                <th>Executed</th>
                                <th>Amount</th>
                                <th>Total</th>
                                <th>Trigger Conditions</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                userOrdersHistory.length !=0 && (userOrdersHistory[0].length !=0 && (
                                    userOrdersHistory[0].map(order=>{
                                        return <tr key={order._id}>
                                            <td>{setDate(order.createdAt)}</td>
                                            <td>BTC/USDT</td>
                                            <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit") || (order.is_market && "Market")}</td>
                                            <td><span className="text-lightred">Sell</span></td>
                                            <td>--------</td>
                                            <td>{(order.is_stop_limit && order.limit) || (order.price)}</td>
                                            <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)*10000)/10000}%</td>
                                            <td>{order.order_amount}</td>
                                            <td>{order.total}</td>
                                            <td>{(order.is_stop_limit && "<="+ order.stop) || ("----------")}</td>
                                            <td>{(order.amount==0 && "Completed") || (order.amount==order.order_amount && "Not Executed") || (order.amount!=0 && "Partial Executed")}</td>
                                        </tr>
                                    })
                                ))

                            }   
                            {
                                 userOrdersHistory.length !=0 && (userOrdersHistory[1].length !=0 && (
                                    userOrdersHistory[1].map(order=>{
                                        return <tr>
                                            <td>{setDate(order.createdAt)}</td>
                                            <td>BTC/USDT</td>
                                            <td>{(order.is_limit && "Limit") || (order.is_stop_limit && "Stop Limit") || (order.is_market && "Market")}</td>
                                            <td><span className="text-lightgreen">Buy</span></td>
                                            <td>--------</td>
                                            <td>{(order.is_stop_limit && order.limit) || (order.price)}</td>
                                            <td>{Math.trunc(((1-(order.amount/order.order_amount)) * 100)*10000)/10000}%</td>
                                            <td>{order.order_amount}</td>
                                            <td>{order.total}</td>
                                            <td>{(order.is_stop_limit && ">="+ order.stop) || ("----------")}</td>
                                            <td>{(order.amount==0 && "Completed") || (order.amount==order.order_amount && "Not Executed") || (order.amount!=0 && "Partial Executed")}</td>
                                        </tr>
                                    })
                                ))
                            }

                            </tbody>
                        </Table>
                        </div>
                   
                            </>
                        }
                        {
                            order == "tradehistory" &&
                            <>
                            <div className="spot-filter">
                            <div>
                                <button to={"#"} class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterTrades("get_today_user_trades")}>1 Day</button>
                                <button to={"#"} class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterTrades("get_week_user_trades")}>1 Week</button>
                                <button to={"#"} class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterTrades("get_month_user_trades")}>1 Month</button>
                                <button to={"#"} class="custom-btn-x-sm-sec btn-gray1" onClick={() => fetchfilterTrades("get_3months_user_trades")}>3 Months</button>
                            </div>

                            <div className="from-to">
                            <div>
                            <span>From</span>
                            </div>
                            <div>
                                <input type="date" value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}} className="trade-input"/>
                            </div>
                            <div>
                            <span>To</span>
                            </div>
                            <div>
                                <input type="date" value={toDate} onChange={(e)=>{setToDate(e.target.value)}} className="trade-input"/>
                            </div>
                            </div>
                            
                            <div>
                            <button  class="custom-btn-x-sm-sec btn-gray1" onClick={fetchCustomDateTrades}>Search</button>
                                <button  class="custom-btn-x-sm-sec btn-gray1" onClick={() => {
                                    setFromDate("")
                                    setToDate("")}}>
                                        Reset 
                                    </button>
                            </div>
                            </div>
                            <div className="table-responsive right-order-one-table">
                            <Table >
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Pair</th>
                                <th>Side</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Fee</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                 userTradesHistory.length > 0 && 
                                 userTradesHistory.length !=0 && (userTradesHistory[1].length !=0 && (
                                    userTradesHistory[1].map(sell_trade=>{
                                       return <tr key={sell_trade._id}>
                                            <td>{setDate(sell_trade.createdAt)}</td>
                                            <td>BTC/USDT</td>
                                            <td><span className="text-lightred">Sell</span></td>
                                            <td>{(sell_trade.price).toFixed(4)}</td>
                                            <td>{(sell_trade.amount).toFixed(4)}</td>
                                            <td>-------</td>
                                            <td>{(sell_trade.price*sell_trade.amount).toFixed(4)}</td>
                                       </tr> 
                                    })))
                                }
                              
                              {
                                 userTradesHistory.length > 0 && 
                                 userTradesHistory.length != 0 && (userTradesHistory[0].length !=0 && (
                                    userTradesHistory[0].map(buy_trade=>{
                                       return <tr key={buy_trade._id}>
                                            <td>{setDate(buy_trade.createdAt)}</td>
                                            <td>BTC/USDT</td>
                                            <td><span className="text-lightgreen">Buy</span></td>
                                            <td>{(buy_trade.price).toFixed(4)}</td>
                                            <td>{(buy_trade.amount).toFixed(4)}</td>
                                            <td>-------</td>
                                            <td>{(buy_trade.price*buy_trade.amount).toFixed(4)}</td>
                                       </tr> 
                                    })))
                                 }
                              
                
                            </tbody>
                            </Table>
                            </div>
                    
                            </>
                        }
                        {
                            order == "funds" &&
                            <div className="table-responsive">
                            <Table responsive="sm">
                            <thead>
                            <tr>
                                <th>Coin</th>
                                <th>Total Balance</th>
                                <th>Available Balance</th>
                                <th>In Order</th>
                                <th>BTC Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>LUNA/USDT</td>
                                <td>84.15</td>
                                <td>0.99</td>
                                <td>in order</td>
                                <td>84.15</td>
                            </tr>
                
                            </tbody>
                            </Table>
                            </div>
                         
                        }
                        </Col>
                        </Row>
                    </div>
                    </Col>

                    <Col xxl={2} xl={4} lg={4} className="custom-col-tradeview order-border">
                        <div className="spot-left-bar">
                            <h5 className="mt-1">Order Book</h5>
                            <div className="order-icon-flex">
                                <div>
                                    <img src={OrderIconOne}/>
                                </div>
                                <div>
                                    <img src={OrderIconTwo}/>
                                </div>
                                <div>
                                    <img src={OrderIconThree}/>
                                </div>
                            </div>
                            <div className="top-second">
                                <span>Price(USDT)</span>
                                <span>Ammount(BTC)</span>
                                <span>Total</span>
                            </div>
                            <div className="order-body right-order-one">

                                {
                                    sellOrders.length!=0 && sellOrders.map(order=>{
                                        return <div className="sell" key={order._id}>
                                            <span>{order.price}</span>
                                            <span>{order.amount.toFixed(5)}</span>
                                            {/* <span> {Math.trunc(order.amount*10000)/10000} </span> */}
                                            <span>{(order.price*order.amount).toFixed(5)}</span>
                                        </div>
                                    })
                                }

                            </div>
                            <div className="bottom-calc">
                                <h4>{marketPrice }</h4>
                                {/* <span>$43,773.01</span> */}
                            </div>
                            <div className="order-body right-order-one">
                                {
                                    buyOrders.length!=0 && buyOrders.map(order=>{
                                        return <div className="buy" key={order._id}>
                                            <span>{order.price}</span>
                                            <span>{order.amount.toFixed(5)}</span>
                                            <span>{(order.price*order.amount).toFixed(5)}</span>
                                        </div>
                                    })
                                }

                            </div>
                            <div className="mt-2">
                                <h5>Trades</h5>
                            </div>
                            <div className="top-second">
                                <span>Price(BUSD)</span>
                                <span className="f-bas">Amount(TLM)</span>
                                <span>Time</span>
                            </div>
                            <div className="order-body right-order-one">
                                {trades.length!=0 && trades.map(transaction=>{
                                    return <div className="" key={transaction._id}>
                                        <span>{(transaction.price).toFixed(3)}</span>
                                        <span className="text-lightred">{(transaction.amount).toFixed(5)}</span>
                                        <span className="text-lightred">{setTime(transaction.createdAt)}</span>
                                    </div>
                                })}

                            </div>

                            <div className="space"></div>

                        </div>
                    
                    </Col>

                    <Col xxl={2} xl={12} lg={12} className={status == false ? "buy-sell-hide": "custom-col-tradeview buy-sell-border" }>
                    <i className="fa-solid fa-xmark buy-sell-close" onClick={(e)=>setStatus(false)}></i>
                    <div className="buy-sell-section">
                        <div className="top">
                            <h5>Spot</h5>
                            <div className="trade-li li dropdown-custom">
                                <i class="fa-solid fa-ellipsis-vertical pointer"></i>
                                <ul>
                                    <li>
                                        <Link to={"#"} onClick={trade_show}>Trading Rules</Link>
                                    </li>
                                    <li>
                                        <Link to={"/faq/23"}>FAQ</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="buy-sell-tabs">
                            <div className={tradetype == "buy" && "buy-tab-active-green"}>
                            <Link to={"#"} onClick={(e)=>{setTradetype("buy")}}>Buy</Link>
                            </div>
                            <div className={tradetype == "sell" && "buy-tab-active-red"}>
                            <Link to={"#"} onClick={(e)=>setTradetype("sell")}>Sell</Link>
                            </div>
                        </div>
                        <div className="form-top">
                            <span onClick={(e)=>setSpottype('limit')} className={spottype == "limit" && "active"}>Limit</span>
                            <span onClick={(e)=>setSpottype('market')} className={spottype == "market" && "active"}>Market</span>
                            <span onClick={(e)=>setSpottype('stop-limit')} className={spottype == "stop-limit" && "active"}>Stop-limit</span>
                            <span onClick={(e)=>setSpottype('oco')} className={spottype == "oco" && "active"}>OCO</span>

                            </div>
                            <Form className={tradetype == 'sell' && "d-none"}>
                                {
                                    spottype == 'limit' &&
                                    <div className="limit">
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="number" value={price} onChange={(e)=> setPrice(e.target.value)}/>
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number"
                                        value={amount} onChange={(e)=> setAmount(e.target.value)}
                                        />
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Total</Form.Label>
                                        <Form.Control type="number" value={parseFloat((price*amount).toFixed(10))}/>
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                {
                                    spottype == 'market' &&
                                    <div className="market">
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="number" value={price} onChange={(e)=> 
                                                { setPrice(e.target.value)
                                                e.target.value !="" ? setAmount(e.target.value/sellMarketPrice) : setAmount("")
                                            }}/>
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number" 
                                            value={amount} onChange={(e)=> {
                                                setAmount(e.target.value) 
                                                e.target.value !="" ? setPrice(e.target.value*sellMarketPrice) : setPrice("")
                                            }}
                                        />
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                {
                                    spottype == 'stop-limit' &&
                                    <div className="stop-limit">
                                     <Form.Group className="custom-group-margin" controlId="stop">
                                        <div className="position-relative">
                                        <Form.Label>Stop</Form.Label>
                                        <Form.Control type="number"
                                            value={stop} onChange={(e)=>setStop(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="limit">
                                        <div className="position-relative">
                                        <Form.Label>Limit</Form.Label>
                                        <Form.Control type="number" value={limit} onChange={(e)=>setLimit(e.target.value)}/>
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="ammount">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number" value={amount} onChange={(e)=>setAmount(e.target.value)}/>
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="total">
                                        <div className="position-relative">
                                        <Form.Label>Total</Form.Label>
                                        <Form.Control type="number"
                                        value={limit*amount}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                  {
                                    spottype == 'oco' &&
                                    <div className="oco">
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="number" 
                                          value={price} onChange={(e)=> setPrice(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                     <Form.Group className="custom-group-margin" controlId="stop">
                                        <div className="position-relative">
                                        <Form.Label>Stop</Form.Label>
                                        <Form.Control type="number" 
                                            value={stop} onChange={(e)=> setStop(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="limit">
                                        <div className="position-relative">
                                        <Form.Label>Limit</Form.Label>
                                        <Form.Control type="number" 
                                         value={limit} onChange={(e)=> setLimit(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="ammount">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number"
                                         value={amount} onChange={(e)=> setAmount(e.target.value)}
                                        />
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="total">
                                        <div className="position-relative">
                                        <Form.Label>Total</Form.Label>
                                        <Form.Control type="number" 
                                         value={limit*amount}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                
                                <div className="mt-3 mb-4">
                                    <button className="custom-btn green-btn w-100 m-0" onClick={makeBuyOrder}>Buy</button>
                                </div>
                            </Form>
                            <Form className={tradetype == 'buy' && "d-none"}>
                                {
                                    spottype == 'limit' &&
                                <div className="limit">
                                <Form.Group className="custom-group-margin" controlId="price">
                                    <div className="position-relative">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" value={price} onChange={(e)=> setPrice(e.target.value)}/>
                                    <span className="text-ab">USDT</span>
                                    </div>
                                </Form.Group>
                                <Form.Group className="custom-group-margin" controlId="price">
                                    <div className="position-relative">
                                    <Form.Label>Ammount</Form.Label>
                                    <Form.Control type="number" value={amount} onChange={(e)=> setAmount(e.target.value)}/>
                                    <span className="text-ab">BTC</span>
                                    </div>
                                </Form.Group>
                                <Form.Group className="custom-group-margin" controlId="price">
                                    <div className="position-relative">
                                    <Form.Label>Total</Form.Label>
                                    <Form.Control type="number" value={parseFloat((price*amount).toFixed(10))}/>
                                    <span className="text-ab">USDT</span>
                                    </div>
                                </Form.Group>
                                </div>
                                }
                                {
                                    spottype == 'market' &&
                                    <div className="market">
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="number" 
                                        value={price} onChange={(e)=> 
                                            { setPrice(e.target.value)
                                                e.target.value !="" ? setAmount(parseFloat((e.target.value/buyMarketPrice).toFixed(10))) : setAmount("")
                                            }
                                        }
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number" 
                                        value={amount} onChange={(e)=> 
                                            { 
                                                setAmount(e.target.value)
                                                e.target.value !="" ? setPrice(parseFloat((e.target.value*buyMarketPrice).toFixed(10))) : setPrice("")
                                            }
                                        }
                                        />
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                    {
                                    spottype == 'stop-limit' &&
                                    <div className="stop-limit">
                                     <Form.Group className="custom-group-margin" controlId="stop">
                                        <div className="position-relative">
                                        <Form.Label>Stop</Form.Label>
                                        <Form.Control type="number" 
                                        value={stop} onChange={(e)=>setStop(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="limit">
                                        <div className="position-relative">
                                        <Form.Label>Limit</Form.Label>
                                        <Form.Control type="number" 
                                        value={limit} onChange={(e)=>setLimit(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="ammount">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number" 
                                        value={amount} onChange={(e)=>setAmount(e.target.value)}
                                        />
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="total">
                                        <div className="position-relative">
                                        <Form.Label>Total</Form.Label>
                                        <Form.Control type="number" value={limit*amount}/>
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                {
                                    spottype == 'oco' &&
                                    <div className="oco">
                                    <Form.Group className="custom-group-margin" controlId="price">
                                        <div className="position-relative">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="number"
                                        value={price} onChange={(e)=>setPrice(e.target.value)} 
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                     <Form.Group className="custom-group-margin" controlId="stop">
                                        <div className="position-relative">
                                        <Form.Label>Stop</Form.Label>
                                        <Form.Control type="number" 
                                            value={stop} onChange={(e)=>setStop(e.target.value)}                                 
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="limit">
                                        <div className="position-relative">
                                        <Form.Label>Limit</Form.Label>
                                        <Form.Control type="number" 
                                            value={limit} onChange={(e)=>setLimit(e.target.value)}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="ammount">
                                        <div className="position-relative">
                                        <Form.Label>Ammount</Form.Label>
                                        <Form.Control type="number" 
                                            value={amount} onChange={(e)=>setAmount(e.target.value)}
                                        />
                                        <span className="text-ab">BTC</span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="custom-group-margin" controlId="total">
                                        <div className="position-relative">
                                        <Form.Label>Total</Form.Label>
                                        <Form.Control type="number" 
                                            value={limit*amount}
                                        />
                                        <span className="text-ab">USDT</span>
                                        </div>
                                    </Form.Group>
                                    </div>
                                }
                                
                                <div className="mt-3 mb-4">
                                    <button className="custom-btn red-btn w-100" onClick={makeSellOrder}>Sell</button>
                                </div>
                            </Form>
                    </div>
                    <div className="buy-sell-footer">
                        <h5>Asset</h5>
                        <Link to={"/wallet/deposit"} className="custom-btn-x-sm btn-gray">Deposit</Link>
                        <Link to={"#"} className="custom-btn-x-sm btn-gray" onClick={transfer_show}>Transfer</Link>
                        <Link to={"/wallet/withdrawal"} className="custom-btn-x-sm btn-gray m-0">Withdraw</Link>
                        <div className="mt-3">
                        <div className="d-flex justify-content-between">
                            <span className="text-white">BTC <span style={{color:'var(--gray)'}}>Available:</span></span>
                            <span className="text-white">{(price1).toFixed(5)}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="text-white">USDT <span style={{color:'var(--gray)'}}>Available:</span></span>
                            <span className="text-white">{(price2).toFixed(2)}</span>
                        </div>
                        </div>
                    </div>
                    </Col>
                                
                </Row>

            </div>
            <TradeRuleModal show={show} trade_close={trade_close}/>
            <LessAmountError show={errorShow} trade_close={error_close} tradetype={tradetype} price={availPrice2} price1={availPrice1} amount={amount} total={spottype=="market" ? price : (
                (spottype=="stop-limit" || spottype=="oco")  ? limit*amount :
                parseFloat((price*amount).toFixed(10)))} fields={
                (
                (spottype=="market" && (price=="" || amount=="")) ||
                (spottype=="limit" && (price=="" || amount=="")) ||
                (spottype=="stop-limit" && (stop=="" || limit=="" || amount=="")) ||
                (spottype=="oco" && (stop=="" || price=="" || limit=="" || amount==""))
                ) ? true : false}/>

            <TransferModal class="black" transfershow={transfershow} transfer_close={transfer_close}/>

            <div className="buy-sell-stick">
                <button className="custom-btn green-btn w-25 text-center" onClick={(e)=>setStatus(true)}>BUY</button>
                <button className="custom-btn red-btn w-25 text-center" onClick={(e)=>setStatus(true)}>SELL</button>
            </div>

            {/* {props.footer} */}

        </div>
    )

}
export default SpotTrade;