import axios from "axios";
import { useEffect, useState } from "react";
import {Form, Row,Col,Table} from "react-bootstrap";
import { Link } from "react-router-dom";


function UserTradeHistory(props){
    const [fromDate,setFromDate] = useState("")
    const [toDate,setToDate]=useState("")
    const [type, setType] = useState("All")

    const [userTradesHistory,setUserTradesHistory] = useState([]) 

  // getting data of multiple traders
  const updateFromMultipeTraders = (trades) =>{
    let userid=trades.data.user_id
    let buy_multiple_trade=trades.data.sell_trades.map((trade)=>{
        if(trade.sell_user_id.length>1){
            trade.amount=0
            trade.sell_user_id.map((id,i)=>{
                if(id===userid){
                    trade.amount+=trade.sell_id[i].amount
                }
            })
        }
    })

    
    let sell_multiple_trade=trades.data.buy_trades.map((trade)=>{
        if(trade.buy_user_id.length>1){
            trade.amount=0
            trade.buy_user_id.map((id,i)=>{
                if(id===userid){
                    trade.amount+=trade.buy_id[i].amount
                }
            })
        }
    })
}

//consuming api of tradehistory of particular user
const fetchUserTrades = async () =>{
    try{
        const trades=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_trades_history`,
        {
            headers:{
            "Authorization": "Bearer "+ localStorage.getItem("token")
        }})
        updateFromMultipeTraders(trades)
        setUserTradesHistory([trades.data.buy_trades,trades.data.sell_trades])
    }
    catch(e) {
        console.log(e)
    }
}   

const fetchCustomDateTrades = async () =>{
    console.log(fromDate)
    console.log(toDate)
    let userid;
    if(fromDate!="" && toDate!=""){
        try{
            const trades=await axios.post(`${process.env.REACT_APP_API_URL}/api/get_custom_user_trades`,{
                from:fromDate,
                to:toDate
            },
            {
                headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }})
            updateFromMultipeTraders(trades)
            if(type=="All")
                setUserTradesHistory([trades.data.buy_trades,trades.data.sell_trades])
            else if(type=="Buy")
                setUserTradesHistory([trades.data.buy_trades,[]])
            else
                setUserTradesHistory([[],trades.data.sell_trades])
            userid=trades.data.user_id

        }
        catch(e) {
            console.log(e)
        }
    }
    else{
        alert("Please select From date and To date!")
    }
}

useEffect(() => {
    fetchUserTrades()
}, [])

const setDate = (createdAt) =>{
    var d = new Date(createdAt)
    let date = d.toLocaleDateString() + " " + d.toLocaleTimeString();
    return date
}

    return(
        <>        
            {props.header}

            <div className="dashboard-section order-body-width">

                {props.sidebar}

                <div className="dashboard-main">

                <div className="normal-box big-bg order-head-mobile">
                    <div>
                    <span>Spot</span>
                    <h3>Trade History</h3>
                    </div>
                    <i class="fa-solid fa-angle-right open-order-sidebar"></i>
                </div>
                <div className="dashboard-margin">
                <div className="history-filter">
                <Form.Group className="mt-3 div" controlId="from">
                  <Form.Label>From</Form.Label>
                  <Form.Control type="date"  value={fromDate} onChange={(e) => setFromDate(e.target.value)}  />
                </Form.Group>
                <Form.Group className="mt-3 div" controlId="to">
                  <Form.Label>To</Form.Label>
                  <Form.Control type="date"   value={toDate} onChange={(e) => setToDate(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mt-3 div" controlId="Pair">
                <Form.Label>Pair</Form.Label>
                <Form.Select >
                <option>All</option>
                <option value="1">1INCH</option>
                <option value="2">1INCHDOWN</option>
                <option value="3">1INCHUP</option>
                 </Form.Select>
                 </Form.Group>
                 <Form.Group className="mt-3 div" controlId="Coin">
                <Form.Label>Coin</Form.Label>
                <Form.Select >
                <option>All</option>
                <option value="1">USDT</option>
                <option value="2">BUSD</option>
                <option value="3">BNB</option>
                 </Form.Select>
                 </Form.Group>
     
                 <Form.Group className="mt-3 div" controlId="side">
                <Form.Label>Side</Form.Label>
                <Form.Select value={type} onChange={(e) =>{setType(e.target.value)}}>
                <option value="All">All</option>
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
                 </Form.Select>
                 </Form.Group >
                 <div className="mt-5 div margin-between-btn">
                     <button className="custom-btn primary-btn" onClick={fetchCustomDateTrades}>Search</button>
                     <button className="custom-btn secondary-btn"  onClick={() => {
                                    setFromDate("")
                                    setToDate("")
                                    setType("All")}}>Reset</button>
                 </div>
                </div>
                </div>
                <div className="mt-3 table-responsive">
                <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Filled</th>
                            <th>Fee</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                                 userTradesHistory.length > 0 && 
                                 userTradesHistory.length !=0 && (userTradesHistory[1].length !=0 && (
                                    userTradesHistory[1].map(sell_trade=>{
                                       return <tr key={sell_trade._id}>
                                            <td>{setDate(sell_trade.createdAt)}</td>
                                            <td>BTC/USDT</td>
                                            <td><span className="text-lightred">Sell</span></td>
                                            <td>{(sell_trade.price).toFixed(4)}</td>
                                            <td>{(sell_trade.amount).toFixed(4)}</td>
                                            <td>-------</td>
                                            <td>{(sell_trade.price*sell_trade.amount).toFixed(4)}</td>
                                       </tr> 
                                    })))
                                }
                              
                              {
                                 userTradesHistory.length > 0 && 
                                 userTradesHistory.length != 0 && (userTradesHistory[0].length !=0 && (
                                    userTradesHistory[0].map(buy_trade=>{
                                       return <tr key={buy_trade._id}>
                                            <td>{setDate(buy_trade.createdAt)}</td>
                                            <td>BTC/USDT</td>
                                            <td><span className="text-lightgreen">Buy</span></td>
                                            <td>{(buy_trade.price).toFixed(4)}</td>
                                            <td>{(buy_trade.amount).toFixed(4)}</td>
                                            <td>-------</td>
                                            <td>{(buy_trade.price*buy_trade.amount).toFixed(4)}</td>
                                       </tr> 
                                    })))
                                 }
                              
              
                        </tbody>
                    </Table>
                </div>
                </div>
             
            
            </div>

          

        </>

    )

}

export default UserTradeHistory