import axios from "axios";
import { useEffect, useState } from "react"
import { Row,Col, Tab, Tabs, Table,Modal,Button } from "react-bootstrap"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import CanvasJSReact from "../../assets/canvasjs.react";
import { fetchLoginDevice } from "../../Services/Auth/loginlogService";

function Dashboard(props){
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const logid=useSelector(state=>state.deviceLogReducer.logon_device)
    
    const [userWallets,setUserWallets] = useState([])
    const [copy,setCopy] = useState([])
    const [copy2,setCopy2] = useState([])
    const [userBuyOrders,setUserBuyOrders] = useState([])
    const [userSellOrders,setUserSellOrders] = useState([])

    // fetch open orders of particular user
    const get_open_orders = async () => {
        const orders=await axios.get(`${process.env.REACT_APP_API_URL}/api/get_open_orders`,{
            headers:{
                "Authorization": "Bearer "+ localStorage.getItem("token")
            }
        })
        setUserBuyOrders(orders.data.buy_orders)
        setUserSellOrders(orders.data.sell_orders)
    }

    useEffect(() => {
        get_open_orders()
    }, [])
    
    const fetchWallets= async () => {
        const wallets=await axios.get(`${process.env.REACT_APP_API_URL}/api/user_wallets`,
        { 
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        if(wallets.data.wallets.length>0){
            setUserWallets(
                wallets.data.wallets.map((wallet,i)=>{
                    return {name:(wallet.coin_id.name).toUpperCase(), y:wallet.balance}
                })
                )
            setCopy(
                wallets.data.wallets.map((wallet,i)=>{
                    return {name:wallet.coin_id.name, y:((i==0 && wallet.balance * 27605.93) || (i==1 && wallet.balance ) || (i==2 && wallet.balance  * 1966.75))}
                })
            )
            setCopy2(
                wallets.data.wallets.map((wallet,i)=>{
                    // console.log({name:wallet.coin_id.name,y:wallet.balance})
                    return {name:wallet.coin_id.name, y:((i==0 && wallet.balance) || (i==1 && wallet.balance / 27605.93) || (i==2 && (wallet.balance  * 1966.75)/27605.93))}
                })
            )
        }
    }
    let options;
    const [deviceDetails,setDeviceDetails] = useState({})
    useEffect(() => {   
      fetchWallets()
      const call = async () => {
          const details=await fetchLoginDevice(logid)
          setDeviceDetails(details)
      }
      call()
    }, [])
    
    options = {
        animationEnabled: true,
        height: 100,
        title: {
            text: ""
        },
        subtitles: [{
            text: "",
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            yValueFormatString: "#,###''",
            dataPoints: userWallets
        }]
    }

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [balanceFlag,setBalanceFlag] = useState(false)

    const state=useSelector(state=>state.userReducer.userDetails)
    useEffect(() => {
        if(state){
            (state.email && state.phone) && handleClose()
        }
    }, [state])
    
    const setDate = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString();
        return date
    }


    const setTime = (createdAt) =>{
        var d = new Date(createdAt)
        let date = d.toLocaleDateString() + " " + d.toLocaleTimeString();
        return date
    }


    const deleteOrder = async (id,type) => {
        if(type=="buy"){
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_buy_order/${id}`,
            {
                headers:{
                    Authorization:`Bearer ${localStorage.getItem("token")}`
                }
            })
        }
        else{
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_sell_order/${id}`,
            {
                headers:{
                    Authorization:`Bearer ${localStorage.getItem("token")}`
                }
            })
        }
        get_open_orders()
    }


    return (
        <>
        {props.header}
        
        

                <Modal show={show} onHide={handleClose} centered className="security-verify-modal">
                    
                {/* <Modal.Header closeButton className="">
                
                </Modal.Header> */}

                        <Modal.Body className="box-bg">

                        <i class="fa-solid fa-xmark" onClick={handleClose}></i>

                        <div className="modal-title text-center">
                            <h3>Security Verification</h3>
                            <p>Enable 2FA, Email Authentication, to increase your account security</p>
                        </div>

                        <div className="modal-box-verify text-center">

                            <Link to={'/security'}>

                                <img src={require("../../assets/images/emails.png").default} />
                                <p>Enable Email Verification</p>

                            </Link>

                            <Link to={'/security'}>

                                <img src={require("../../assets/images/verification.png").default} />
                                <p>Enable Phone Verification</p>

                            </Link>

                        </div>


                        <Link to={'/'} className="custom-icon-btn primary-btn m-0">Continue</Link>
                        

                        </Modal.Body>
                        
                </Modal>

        <div className="dashboard-section">

            {props.sidebar}

            <div className="dashboard-main">
                <div className="normal-box big-bg">
                    <h3>Welcome to PayRexa</h3>
                    <p>Just a few more steps and you're good to go!</p>
                    <div class="verification">
                        <div class="verification-item">

                            <div class="verification-circle circle-done">
                                1
                            </div>

                            
                            <div className="verification-content">

                                <p className="f-bold">Register Account</p>

                            </div>

                        </div>

                        <hr class="verification-hr" />

                        <div class="verification-item">

                            <div class="verification-circle">
                                2
                            </div>

                            <div className="verification-content">

                            <p className="f-bold">2FA</p>
                            <Link to={'#'} className="custom-btn primary-btn m-0" onClick={handleShow}>Verify</Link>

                            </div>

                        </div>

                        <hr class="verification-hr" />

                        <div class="verification-item">

                            <div class="verification-circle">
                                3
                            </div>

                            <div className="verification-content">

                                <p className="f-bold">Deposit Funds</p>

                            </div>

                            </div>

                    </div>


                </div>

                <div className="dashboard-margin">
                <Row className="gy-5">
                    <Col lg={7}>
                    <div className="normal-box">
                    <div className="box-top">
                    <h4>Balance Details</h4>
                    <div className="d-j-flex">
                    <Link to={'/wallet/deposit'} class="custom-btn-sm">Deposit</Link>
                    <Link to={'/wallet/withdrawal'} class="custom-btn-sm-sec">Withdraw</Link>
                    </div>
                    </div>

                    <div className="box-tabs">
                    <Tabs defaultActiveKey="Spot" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="Spot" title="Spot">
                        <div className="balance-details">
                            <div className="text-center position-relative">
                            <h6>Account Balance</h6>
                            <i class={`fa  ${balanceFlag ? "fa-eye" : "fa-eye-slash"} balance-icon`} onClick={() => balanceFlag ? setBalanceFlag(false) : setBalanceFlag(true) }></i>
                            </div>
                            <div className="total-balance text-center">
                                <h2 className={`${!balanceFlag && "balance_size"}`}>{balanceFlag ? (copy2.length >0 && (copy2[0].y + copy2[1].y + copy2[2].y).toFixed(6)) : "************" }  </h2><span>{ balanceFlag && "BTC"}</span>
                            </div>
                            {userWallets.length>0 && <CanvasJSChart options = {options}/>}
                            <div className="bar-section ">
                                <div className="text-center w-100">
                                    <h6>Estimate Value</h6>
                                    <h2 className={`${!balanceFlag && "balance_size"}`}> {balanceFlag && "$"}{balanceFlag ? (copy.length >0 && (copy[0].y + copy[1].y + copy[2].y).toFixed(2)) : "************"}</h2>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab defaultActiveKey="Activity" eventKey="P2P" title="P2P">
                    <div className="balance-details">
                            <div className="text-center position-relative">
                            <h6>Account Balance</h6>
                            <i class="fa fa-eye-slash balance-icon"></i>
                            </div>
                            <div className="total-balance text-center">
                                <h2>{0.00}</h2><span>BTC</span>
                            </div>
                            <div className="bar-section">
                                <div>
                                    <h6>Estimate Value</h6>
                                    <h2>$ 0.00</h2>
                                </div>
                                <div>
                                    <h6>Estimate Value</h6>
                                    <h2>$ 0.00</h2>
                                </div>
                                <div>
                                    <h6>Estimate Value</h6>
                                    <h2>$ 0.00</h2>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    </Tabs>
                    </div>
                    </div>
                    </Col>
                    <Col lg={5}>
                    <div className="normal-box">
                    <div className="box-tabs">
                    <Tabs id="uncontrolled-tab-example" className="mb-3">
                   
                    <Tab eventKey="Device" title="Device">
                    <div className="activity-section">
                            <div className="activity-box">
                                    { deviceDetails &&  <>
                                    <div>
                                        <p className="title">Device</p>
                                        <span>{deviceDetails.location}</span>
                                        
                                        </div>
                                        <div>
                                        <p>{deviceDetails.platform}</p>
                                        <span>{setTime(deviceDetails.createdAt)}</span>
                                        </div>
                                    
                                    </>
                                    }

                            </div>
                        </div>
                    </Tab>
                 
                    </Tabs>
                    <Link to={'/security/disable-account'} className="red pointer disable-btn">Disable Account</Link>
                    </div>
                    </div>
                    </Col>
                </Row>
                </div>
                <div className="dashboard-margin">
                <div class="normal-box">
                    <h4>Announcement</h4>
                    <hr/>
                    <div className="announcement-section">
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <span className="flex-end">24/12/2020</span>
                        <hr/>
                    </div>
                    <div className="announcement-section">
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <span className="flex-end">24/12/2020</span>
                        <hr/>
                    </div>
                    <div className="announcement-section">
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <span className="flex-end">24/12/2020</span>
                        <hr/>
                    </div>
                </div>
                </div>
                <div className="dashboard-margin">
                <Row className="gy-5">
                    <Col lg={5}>
                    <div class="normal-box">
                        <h4>Increase your account security</h4>
                        <hr/>
                        <div className="security-section">
                            <ul>
                                <li><span>Enable 2FA</span> <a className="custom-btn-sm m-0" href="#">ON</a></li>
                                <li><span>Identification Verication</span> <a className="custom-btn-sm m-0" href="#">Verify</a></li>
                                <li><span>Anti-phishing Code</span> <a className="custom-btn-sm m-0" href="#">Setup</a></li>
                                <li><span>Turn-on Withdrawal Whitelist</span> <a className="custom-btn-sm m-0" href="#">Turn on</a></li>
                            </ul>
                        </div>
                    </div>
                    </Col>
                    <Col lg={7}>
                    <div class="normal-box">
                        <h4>Open Orders</h4>
                        <hr/>
                        <Table responsive>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Type/Side Trigger Conditions</th>
                            <th>Price Ammount</th>
                            <th>Total Filled</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                userSellOrders.length > 0 && userSellOrders.map(order=>{
                                    return  <tr>
                                    <td>{setDate(order.createdAt)}</td>
                                    <td>{(order.is_limit && "Limit ") || (order.is_stop_limit && "Stop Limit ") || order.is_market && "Market "}
                                    / {(order.is_limit && "------") || (order.is_stop_limit && "<="+ order.stop)}

                                    </td>
                                    {/* <td><span className="text-lightred">Sell</span> </td> */}
                                    <td>{order.order_amount}</td>
                                    <td>{parseFloat(((1-(order.amount/order.order_amount))*100).toFixed(1))}%</td>
                                    <td ><span className="cancelorder" onClick={()=>deleteOrder(order._id,"sell")}>cancel </span></td>
                                </tr>
                                })


                            }
                            {
                                userBuyOrders.length > 0 && userBuyOrders.map(order=>{
                                    return  <tr>
                                    <td>{setDate(order.createdAt)}</td>
                                    <td>{(order.is_limit && "Limit ") || (order.is_stop_limit && "Stop Limit ") || order.is_market && "Market "}
                                       / {(order.is_limit && "------") || (order.is_stop_limit && ">="+ order.stop)}
                                    </td>
                                    {/* <td><span className="text-lightgreen">Buy</span> </td> */}
                                    <td>{order.order_amount}</td>
                                    <td>{parseFloat(((1-(order.amount/order.order_amount))*100).toFixed(1))}%</td>
                                    <td ><span className="cancelorder" onClick={()=>deleteOrder(order._id,"buy")}>cancel </span></td>
                                </tr>
                                })
                            }                       
                        </tbody>
                    </Table>
                    </div>
                    </Col>
                </Row>
               
                </div>
            </div>
        </div>
        </>
    )
}
export default Dashboard