import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";  

function Footer(){
    return (
        <>
   
 
    <footer className="footer-section">
    <Container>
    
        <Row className="position-relative w-100">
          <Col sm={12} lg={3} md={6}>
            <img width="100" src={require("../assets/images/footer_logo.png").default}/>
          
          <ul class="list-unstyled d-flex footer-icon">
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-twitter"></i></a></li>
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-telegram"></i></a></li>
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-youtube"></i></a></li>
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-medium"></i></a></li>
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-discord"></i></a></li>
            <li class=""><a class="link-dark" href="#"><i class="fa-brands fa-github"></i></a></li>
          </ul>

          <p className="copyright">2022 PayRexa All Rights Reserved</p>
          </Col>

          <Col lg={3} md={6} className="sub-footer">
            <h2 className="text-white">Company</h2>
            <ul class="nav flex-column">
              <li class="nav-item"><Link to={"/support-center"} class="nav-link text-muted">Support Center</Link></li>
              <li class="nav-item"><Link to={"#"} class="nav-link text-muted">Contact Us</Link></li>
              <li class="nav-item"><Link to={"#"} class="nav-link text-muted">Blog</Link></li>
              <li class="nav-item"><Link to={"#"} class="nav-link text-muted">Get In Touch</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} className="sub-footer">
            <h2 className="text-white">Products</h2>
            <ul class="nav flex-column">
              <li class="nav-item"><Link to={"/buy/usdt"} class="nav-link text-muted">P2P Trade</Link></li>
              <li class="nav-item"><Link to={"/spot/trade"} class="nav-link text-muted">Spot Trade</Link></li>
              <li class="nav-item"><Link to={"/buy/usdt"} class="nav-link text-muted">Instant Buy</Link></li>
              <li class="nav-item"><Link to={"/sale/usdt"} class="nav-link text-muted">Instant Sell</Link></li>
              <li class="nav-item"><Link to={"/fiat-orders"} class="nav-link text-muted">P2P Orders</Link></li>
              <li class="nav-item"><Link to={"/spot/open-order"} class="nav-link text-muted">Spot Orders</Link></li>
              <li class="nav-item"><Link to={"/wallet/overview"} class="nav-link text-muted">Wallet</Link></li>

            </ul>
          </Col>

          <Col lg={3} md={6} className="sub-footer">
            <h2 className="text-white">Learn</h2>
            <ul class="nav flex-column">
              <li class="nav-item"><Link to={"/market"} class="nav-link text-muted">Market</Link></li>
              <li class="nav-item"><Link to={"/launch/pad"} class="nav-link text-muted">Launchpad</Link></li>
            </ul>
          </Col>

          {/* <Col sm={12} lg={3} md={6}>
          <h2 className="text-white">Contact Us</h2>
          <p className="text-white footer-text">
                <b>ABC,Your Address, NYC, <br/> USA</b>
          </p>
          <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">info@example.com</a></li>
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">+1 (333) 32423423</a></li>
            </ul>
          </Col> */}
        </Row>

    </Container>
    </footer>
    
        </>
    )
}
export default Footer;