import axios from "axios";
import { useState } from "react";
import { Row,Col, Tab, Tabs, Table, Modal, Form } from "react-bootstrap"
import { Link } from "react-router-dom";
import validator from "validator"
import {useDispatch} from "react-redux"
import { fetchUserData } from "../../store/actions";
import { fetchUser } from "../../Services/UserCentricService";

function EmailModal(props){

    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState("")
    const [emailError,setEmailError] = useState("")
    const [otpError,setOtpError] = useState("")
    const [flag,setFlag] = useState(false)
    const [message,setMessage] = useState("")
    
    const dispatch= useDispatch()
    const sendCode = async () =>{
        setOtpError("")

        if(validator.isEmail(email)){
            setEmailError("")
            try {
                let data={
                    email:email
                }
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/send-otp`,data,{
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token"),
                    }
                })            
                console.log("res :",res)

                setFlag(true)
            } 
            catch (error) {
                // console.log(error.response.data)
                setEmailError(error.response.data.message)

            }
        }
        else{
            setEmailError("Please enter valid email!")
        }
    }

    const handleHide = () =>{
        setEmailError("")
        setEmail("")
        setFlag(false)
        props.email_close()
    }

    const verifyCode = async (e) =>{
        e.preventDefault()
        if(email && otp){
            try {
                let data={
                    email:email,
                    otp:otp
                }
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/2faverify-otp`,data,{
                headers:{
                    "Authorization": "Bearer "+ localStorage.getItem("token"),
                }
                })        
                console.log(res)
                setFlag(false)
                if(res.data.status=="FAILED"){
                    setOtpError(res.data.message)
                }
                else{

                    setMessage(res.data.message)
                    fetchUser(dispatch)
                    setTimeout(()=>{
                        setEmail("")
                        setOtp("")
                        setOtpError("")
                        props.email_close()
                    },3000)
    
                }


            } catch (error) {
                console.log(error.response)
            }
        }
    }

    return (<>
         <Modal show={props.show} onHide={props.email_close} centered className="security-verify-modal">

                    <Modal.Body className="box-bg">

                    <i class="fa-solid fa-xmark" onClick={handleHide}></i>

                    <div className="modal-title text-center">
                        <h3>Email Verification</h3>
                    </div>

                    <Form>
                    <Form.Group className="mt-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />

                    {
                        emailError ? <p className="validation_error">{emailError}</p> : ""
                    }
                    </Form.Group>
                        <Form.Group className="mt-3 " controlId="code">
                        <Form.Label>Email Verification Code</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type="text" value={otp} onChange={(e)=>{e.target.value.length<=6 && setOtp(e.target.value)}}/>
                        <p class="form-icon text-black pointer" onClick={sendCode}>Get Code</p>
                        </div>
                    </Form.Group>
                    {
                        otpError ? <p className="mt-1 f-bold validation_error">{otpError} </p> : ""
                    }
                    { 
                        flag ?  <p className="mt-1 f-bold text-" style={{color:"green"}}> Enter 6 digits code sent to your email</p> : ""
                    }

                    {
                        message ? <p className="mt-3" style={{textAlign:"center",color:"green"}}> {message}</p> : ""
                    }
                    <button className="mt-5 w-100 custom-icon-btn primary-btn" onClick={verifyCode} type="submit">Continue</button>

                    </Form>

                  
                    

                    </Modal.Body>
        
            </Modal>
    </>)
}

export default EmailModal;