import { useEffect, useState } from "react"
import { Col, Container, Form, Modal, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/images/formlogo.png'
import Select, { components } from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from "axios"
const validator = require('validator');


function CountryFlag(props) {
    return (
      <span
        className={"fi fi-" + props.code}
        style={{ fontSize: props.size || "40px",paddingRight:'10px'}}
      />
    );
  }
  const CountryFlagSelectOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
          {props.label}
        </div>
      </components.Option>
    );
  };
  const CountryFlagValueContainer = ({ children, ...props }) => {
    const code = (props.hasValue && props.getValue()[0].value) || false;
  
    return (
      <div style={{ display: "flex", flexGrow: 1 }}>
        {(code && <CountryFlag code={code.toLowerCase()} />) || null}
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      </div>
    );
  };

  
function Register(props){
   
  
    const [regstatus,setRegstatus] = useState('email');
    const [selectcountry,setSelectcountry] = useState("")
    const [show, setShow] = useState(true);
    
    // Mutahir Commit
    
    const [gmail, setGmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [referral, setReferral] = useState()
    const [country,setCountry] = useState("")
    const [error, setError] = useState()
    const [option,setOption] = useState(countryList().getData());
    const [value,setValue] = useState(null);
    const [emailCheck, setEmailCheck] = useState(false)
    const [ageCheck, setAgeCheck] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [cpassError, setCPassError] = useState(false)


    const [countryError, setCountryError] = useState(false)

    const [countrtCode, setCountryCode] = useState("")



    const userSignUp = async () => {

        if(password=="")
          setPassError(true)
        if(confirmPassword=="")
          setCPassError(true)
        if(!value)
          setCountryError(true) 

        if(regstatus=="email"){
          if(gmail=="" || !validator.isEmail(gmail)){
            if(gmail==""){
              setEmailError("Email is Required")
            }
            else if(!validator.isEmail(gmail)){
              setEmailError("Email format is invalid")
            }
        }
        }
        if(regstatus=="phone"){
          if(phone.length<4){
              setPhoneError("Phone Number is Required")
          }
        }

        if((password=="" && confirmPassword=="") || password!=confirmPassword){
          setPasswordError(true)
        }

        if(((phone.length>4 && phoneError == "") || (gmail!="" && emailError=="")) && value !=null &&  (password!="" && !passwordError)){
          try{
            let data;
            gmail ? data={
                password: password,
                userData: {
                    email: gmail,
                    referral: referral,
                    country : value,
                    email_subscribe : emailCheck,
                    age_condition : ageCheck
                }
              } : data={
                password: password,
                  userData: {
                      phone: phone,
                      referral: referral,
                      country : value,
                      email_subscribe : emailCheck,
                      age_condition : ageCheck
                  }
                }
          // console.log("data ",data)
          const signUp = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, data)
            // console.log(signUp.data)
            if(regstatus == 'email'){
              // console.log(signUp)
              // console.log(signUp.data.id)
              navigate('/email/verification',{state:{id:signUp.data.id}})

              // navigate('/login')
            }
            else{
              // navigate('/login')
              navigate('/phone/verification',{state:{id:signUp.data.id}})
              }
            }    
            catch(error){
              if(error.response.data.message=="Email is already taken.")
                setEmailError(error.response.data.message)
              else
                setPhoneError(error.response.data.message)
            }        
          }
   
    
      
    }
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate()
    const ChooseCountry = (e)=>{
        e.preventDefault();
        setCountry(value)
    }
    const SignUp = (e)=>{
        e.preventDefault();
    }
    const changeHandler = value => {
        setCountryError(false)
        setValue(value.label );
        
     };
    

    return (
        <>
        {props.header}
        <Container>
        <div className="form-section-margin">
        <Row className="justify-content-center">
{/*        
                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Body>
                    <Form onSubmit={(e) => {
                        ChooseCountry(e);
                        }}>
                        <h1 className="h3 mb-3 fw-normal text-center">Select Your Country</h1>
                        <p className="text-center">Before we start, please enter your current<br/> location of residence.</p>
                        <h5>Country/State</h5>
                        <Select
                                options={option}
                                value={value}
                                onChange={(e)=>changeHandler()}
                                components={{
                                    Option: CountryFlagSelectOption,
                                    ValueContainer: CountryFlagValueContainer
                                }}
                            />
                        <button className="w-100 custom-btn primary-btn mt-5" type="submit" onClick={handleClose}>Confirm</button>
                    </Form>
                        </Modal.Body>
                    </Modal> */
}
               

                <Col lg="6" sm="12">
            <main className="form-section">
            <Row>
                <Col lg="12">
                <div className="form">
                <Form onSubmit={(e) => {
		        		SignUp(e);
				}}>
                <div className="head-section">
                <img src={Logo}/>
                <h2>Create PayRexa Account</h2>
                </div>
                <div className="d-flex justify-content-center form-ul">
                    <a href="javascript::void()" className={regstatus == "email" ?"active":""} onClick={()=>setRegstatus("email")}>Email</a>
                    <a className="form-slash"><i class="fa-solid fa-slash"></i></a>
                    <a href="javascript::void()" className={regstatus == "phone" ?"active":""} onClick={()=>setRegstatus("phone")}>Phone</a>
                </div>
                <div className="form-floating">
                
                {
                    regstatus == "email" ? (<> 
                    <Form.Group className="" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control onChange={(e) => {
                          setEmailError("")
      
                          setGmail(e.target.value)}} type="email"/>
                    </Form.Group>
                    {emailError && <p  style={{color:"red", marginBottom:"12px"}} >{emailError} </p>}
                    </>)
                    : (<>
                 <Form.Group className="" controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <div className="d-flex">
                        <PhoneInput
                            country={'us'}
                            enableSearch={true}
                            value={phone}
                            onChange={(e) => {
                              setPhoneError("")
                              setPhone(e)}}
                            className="form-control"
                          />
                        </div>
                    </Form.Group>
                    {phoneError && <p  style={{color:"red", marginBottom:"12px"}} >{phoneError} </p>}

                    </>)
                }
                </div>
                <Form.Group className="mt-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control onChange={(e) => {
                          setPassError(false)
                          setPassword(e.target.value)}} type="password"/>
                </Form.Group>
                {passError && <p  style={{color:"red", marginBottom:"12px"}} >Password is Required </p>}

                <Form.Group className="mt-3" controlId="cpassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control onChange={(e) => {
                           setCPassError(false) 
                           setPasswordError( (e.target.value == "" || password == e.target.value) ? false : true)
                          setConfirmPassword(e.target.value)}} type="password"/>

                </Form.Group>
                {(cpassError && <p  style={{color:"red", marginBottom:"12px"}} >Confirm Password is Required</p>)  || ( passwordError && <p  style={{color:"red", marginBottom:"12px"}} >Password and confirm Password do not matches </p>)}
                <Form.Group className="mt-3" controlId="referral">
                        <Form.Label>Referral or Promo Code</Form.Label>
                        <Form.Control onChange={(e) => setReferral(e.target.value)} type="text"/>
                </Form.Group>

                <Form.Group className="mt-3" controlId="referral">
                  <Form.Label>Country/State</Form.Label>
                  <Select className="country-select" options={option} onChange={(e)=>changeHandler(e)} components={{
                                      Option: CountryFlagSelectOption,
                                      ValueContainer: CountryFlagValueContainer
                                  }}
                  />
                </Form.Group>
                { countryError && <p style={{color:"red", marginBottom:"12px"}}> Country is Required</p> }
                {
                    regstatus == "email" ? <>
                    <div class="form-group checkbox mb-3 mt-3 d-flex">
                <label class="AuthFormCheckbox_container_2-cgs"><input type="checkbox" className="input-checkbox" value="" onChange={(e) => {
                  setEmailCheck(e.target.checked)}}/>
                <span class="AuthFormCheckbox_checkmark_A7HnL"></span>
                 </label>
                 <span className="form-checkbox-text">Subscribe to email updates</span> 
                </div>
                    </>
                    : ""
                }
                
                <div class="form-group checkbox mb-3 mt-3 d-flex">

                <label class="AuthFormCheckbox_container_2-cgs"><input type="checkbox" onChange={(e) => setAgeCheck(e.target.checked)} className="input-checkbox" value=""/>
                <span class="AuthFormCheckbox_checkmark_A7HnL"></span> 
                 </label>
                 <span className="form-checkbox-text">I certify that I am 18 years of age or older, and agree to the User Agreement and Privacy Policy.</span>
                </div>
                <div style={{color:"red", marginBottom:"12px"}}>{error}</div>
                <button className="w-100 custom-btn primary-btn" type="submit" disabled={!(ageCheck) && !passwordError}  onClick={userSignUp}>Sign Up</button>
                <div className="text-center">
                <br/>
                 <p >Already Registered?<Link to="/login" className="anchor">Sign In</Link></p>
                </div>
            </Form>
                </div>
                </Col>
            </Row>
            
            </main>
            </Col>
       
        </Row>
        </div>
        </Container>
        </>
    )
}
export default Register