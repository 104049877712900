import { Modal, Form } from "react-bootstrap"


function LessAmountError(props){
    return (<>
         <Modal show={props.show} onHide={props.trade_close} centered className="trade-rule-modal security-verify-modal">
            <Modal.Body className="">

            <i class="fa-solid fa-xmark" style={{color:"rgb(148, 144, 144)"}} onClick={props.trade_close}></i>
            {
                props.fields == false ? <> <div className="modal-title text-center">
                <h4 className="text-lightred">You have less amount in Wallet</h4>
                <br />
            </div>
            <div className="trade-rule-main">
                <div className="trade-rule">
                    <span>Your Amount in {`${props.tradetype=="buy" ? " USDT" : "BTC" }`}</span>
                    <span>${props.tradetype=="buy" ? props.price: props.price1}</span>
                </div>
                <div className="trade-rule">
                    <span>Order {`${props.tradetype=="buy" ? " Total" : "Amount" }`} in {`${props.tradetype=="buy" ? " USDT" : "BTC" }`}</span>
                    <span>${props.tradetype=="buy" ? props.total : props.amount}</span>

                    {/* <span>${props.total}</span> */}
                </div>
            </div>
            <div className="modal-title text-center">
                <br />
                <h5 className="text-lightred">So Order cannot be placed!</h5>
            </div>
             </> :  
            <> <div className="modal-title text-center">
            <h4 className="text-lightred">Please Fill the Order Fields!</h4>
            <br />
            </div> </>
            }
                
            </Modal.Body>
            </Modal>
    </>)
}

export default LessAmountError;